import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MaterialTable from '@material-table/core';

import Card from '@mui/material/Card';
import { Button, CardActions, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';

import { requestPipelineDetails } from '../../serverCommunication/feedExecutionCommunication';
import AuthContext from '../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import BreadCrumbComponent from '../../components/breadCrumbComponent';
import { closeStream } from '../../serverCommunication/serverCommunication';
import { useFetch } from '../../hooks/useFetch';
import { IconPicker } from '../../components/FeedExecutionIconPicker';
import { useTheme } from '@emotion/react';
import { DownloadFileButton } from '../../components/DownloadFileButton';

const renderMVSubTableRow = (entries) => {
  return entries.map((el) => {
    return (
      <TableRow key={el.quality_test_instance_id}>
        <TableCell>
          <IconPicker
            execution_successfull={el.passed}
            alert_type={el.alert_type}
          />
        </TableCell>
        <TableCell><Tooltip title={el.variable_description}><div>{el.variable}</div></Tooltip></TableCell>
        <TableCell>{el.warning}</TableCell>
        <TableCell><Tooltip title={el.quality_test_description}><div>{el.test}</div></Tooltip></TableCell>
        <TableCell>{el.value}</TableCell>
      </TableRow>);
  });
};

const multivariateTestDetails = (results) => {

  const conditionRows = results.filter(el => el.test_type === "condition_test");
  const effectRows = results.filter(el => el.test_type !== "condition_test");

  const conditions = renderMVSubTableRow(conditionRows);
  const effects = renderMVSubTableRow(effectRows);

  return (<Table>
    <TableBody>
      <TableRow>
        <TableRow>
          <TableCell colSpan={5}>Conditions
          </TableCell>
        </TableRow>
        {conditions}
        <TableRow>
          <TableCell colSpan={5}>
            Effects
          </TableCell>
        </TableRow>
        {effects}

      </TableRow>
    </TableBody>
  </Table>);
};

const univariateTestDetails = (results) => {
  return (<Table>
    <TableBody>
      <TableRow>
        <TableCell>
          {results.warning}
        </TableCell>
        <TableCell>
          <pre>{results.variable_value}</pre>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>);
};

const createLookup = (results, field) => {
  const lookup = {};
  for (const el of results) {
    lookup[el[field]] = el[field];
  }
  return lookup;
};

function PipelineDetails() {

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const pathName = location.pathname;
  const execution_id = pathName.split("/")[pathName.split("/").length - 1];

  const { t } = useTranslation();

  const { getToken } = useContext(AuthContext);

  const [pipeline, setPipeline] = useState({});
  const [mvTestsResults, setMvTestsResults] = useState([]);

  const [dataEntryNumber, setDataEntryNumber] = useState(-1);
  const [selectedPipelineDetails, setSelectedPipelineDetails] = useState([]);

  // Using a state on the columns to keep track of changes
  const [columnObject, setColumnObject] = useState([]);

  const [univariateTable, setUnivariateTable] = useState(<></>);
  const [multivariateTable, setMultivariateTable] = useState(<></>);

  const {data: pipelineMetaData} = useFetch(
    `/request_pipeline_execution_meta_data/${execution_id}`, undefined
  );

  useEffect(() => {
    if (pipelineMetaData !== undefined) {
      setPipeline(pipelineMetaData);
    }
  }, [pipelineMetaData]);

  useEffect(() => {
    if (dataEntryNumber !== -1) {
      requestPipelineDetails(getToken(), execution_id, dataEntryNumber, setSelectedPipelineDetails,
        setMvTestsResults).then(result => {
        setSelectedPipelineDetails(result.pipelineDetails);

        // Iterate through every effect test and look for errors instead of warnings
        for (const mvTest of result.mvTestsResults) {
          for (const mvTestResult of mvTest.multivariate_test_results_values) {
            if (mvTestResult.test_type === "effect_test"
              && mvTestResult.passed === 0
              && mvTestResult.alert_type === "error") {
              mvTest.alert_type = "error";
            }
          }
        }
      });
    }
  }, [getToken, execution_id, dataEntryNumber]);

  useEffect(() => {
    const tmpCols = [
      {
        title: 'Successful',
        field: 'successful',
        type: 'boolean',
        width: 50,
        // data: rowData => (rowData.execution_successful === 1 ? true : false),
        render: rowData => {
          return (rowData.quality_test_variable_name === null) ?
            <IconPicker execution_successfull={1}/> :
            <IconPicker execution_successfull={0}/>;
        },
        // lookup: {
        //   1: 'Success', 0: 'Unsuccessful'
        // }
      },
      {
        title: 'Data Object ID',
        field: 'data_entry_number',
        type: 'numeric',
        render: rowData => `Data Object ${rowData.data_entry_number}`,
      },
      {
        title: 'Errors',
        type: 'string',
        field: 'quality_test_variable_name',
        hidden: false,
        // render: rowData => <DisplayErrors data_entry_number={rowData.data_entry_number} />,
      }
    ];
    setColumnObject(tmpCols);
  }, []);

  useEffect(() => {
    selectedPipelineDetails.length < 1 ? setUnivariateTable(<></>) : // Otherwise table renders twice above each other
      setUnivariateTable(
        <MaterialTable
          key={selectedPipelineDetails.length}
          title={t('Quality tests')}
          columns={[
            {title: 'Success',
              field: 'check_passed',
              type: 'boolean',
              width: 50,
              defaultSort: 'asc',
              render: rowData => <IconPicker
                execution_successfull={rowData.check_passed}
                alert_type={rowData.alert_type}
                testSuffix={'DataObjectDetails'}
              />},
            {title: 'Variable Name',
              field: 'variable_name',
              lookup: createLookup(selectedPipelineDetails, 'variable_name'),
              render: rowData => {
                return (<Tooltip title={rowData.variable_description}>
                  <div>{rowData.variable_name}</div>
                </Tooltip>
                );
              }},
            {title: 'Variable Test',
              field: 'quality_test_name',
              lookup: createLookup(selectedPipelineDetails, 'quality_test_name'),
              render: rowData => {
                return (<Tooltip title={rowData.quality_test_description}>
                  <div>{rowData.quality_test_name}</div>
                </Tooltip>
                );
              }},
          ]}
          data={selectedPipelineDetails}
          detailPanel={[
            (rowData) => ({
              icon: () => (rowData.warning? <KeyboardArrowRightIcon /> : <></>),
              openIcon: () => (rowData.warning? <KeyboardArrowDownIcon /> : <></>),
              disabled: rowData.warning === '',
              render: (_rowData) => {
                return univariateTestDetails(_rowData.rowData);
              },
            }),
          ]}
          options={{
            filtering: true,
            cellStyle: { padding: '0.3em'},
            headerStyle: { padding: '0.3em'},
            pageSize: Math.min(selectedPipelineDetails.length, 10),
            pageSizeOptions: [10, selectedPipelineDetails.length],
          }}
        />
      );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPipelineDetails]);

  useEffect(() => {
    setMultivariateTable(
      <MaterialTable
        key={mvTestsResults.length}
        title={t('Multivariate tests')}
        columns={[
          {title: 'Success',
            field: 'passed',
            type: 'boolean',
            width: 50,
            render: rowData => <IconPicker
              execution_successfull={rowData.passed}
              alert_type={rowData.alert_type}
              testSuffix={'MVDetails'}
            />},
          {title: '(Reason)',
            field: 'reason',
            width: '20%',
            lookup: createLookup(mvTestsResults, 'reason')},
          {title: 'Multivariate Test',
            field: 'multivariate_test_name',
            lookup: createLookup(mvTestsResults, 'multivariate_test_name')},
        ]}
        data={mvTestsResults}
        detailPanel={[{render: rowData => {
          return multivariateTestDetails(rowData.rowData.multivariate_test_results_values);
        }}]}
        options={{
          filtering: true,
          cellStyle: { padding: '0.3em'},
          headerStyle: { padding: '0.3em'},
          pageSize: mvTestsResults.length < 10 ? mvTestsResults.length : 10,
          pageSizeOptions: [mvTestsResults.length, 10],
        }}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mvTestsResults]);

  const {data: feedTestWarnings} = useFetch(`/request_feed_test_warnings/${execution_id}`, []);
  const {data: dataObjects} = useFetch(`/request_feed_data_objects/${execution_id}`, []);
  const {data: aggregatedResults} = useFetch(`/request_pipeline_execution_agg_results/${execution_id}`, []);

  const getCellValue = (cell) =>{
    setDataEntryNumber(cell);
  };

  const assembleVariableTestName = (variable_name, multivariate_test_instance_id, quality_test_name) => {
    return `${variable_name}${multivariate_test_instance_id !== null ? '-MV' : ''}-${quality_test_name}`;
  };

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}, {route: 'Resolution', link: '/pipelines'}]}
          currentRoute={'Feed Execution'} />

        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={12} md={6}>
            <Card>

              <CardHeader
                title={<>
                  <IconPicker execution_successfull={pipeline.execution_successfull}
                    sx={{marginRight: 1, mb: -0.5}} />
                  {pipeline.reference_label ?
                    <Fragment>{pipeline.feed_name}: {pipeline.reference_label} </Fragment> :
                    <Fragment>{pipeline.feed_name}</Fragment>}
                </>}
                action={
                  pipeline.execution_successfull === null ?
                    <Button variant="outlined"
                      onClick={() => closeStream(getToken(), pipeline.execution_result_id, setPipeline)}>
                        Close Streaming
                    </Button> : null}
              />
              {pipeline.execution_successfull !== null && feedTestWarnings.length > 0 ?
                <CardContent>
                  <Typography variant='h6'>{t("Feed Acceptance Warnings")}</Typography>

                  {feedTestWarnings.map(warning => {
                    return (
                      <Grid container direction="row" alignItems="center" key={warning.feed_test_instance_id}>
                        <Grid item><CancelIcon color="error"/>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {warning.feed_test_attribute}: {
                              warning.warning} <br/> Actual value is: {warning.feed_test_value}.</Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </CardContent> :
                <></>}
              <CardContent>
                {(pipeline?.execution_create_time === undefined) ? '' :
                  (pipeline.execution_successfull === null) ?
                    `Feed execution: ${pipeline.feed_name} created ${t('intlDateTime',
                      { val: new Date(pipeline?.execution_create_time)})} ` :
                    `Feed execution: ${pipeline.feed_name} closed ${t('intlDateTime',
                      { val: new Date(pipeline?.execution_close_time)})} `
                }
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  type='submit'
                  onClick={() => navigate(`/editFeed/${pipeline.feed_id}`)}
                >{t('Go to Feed Defintion')}</Button>
                {/* <Button
                  size="small"
                  type='submit'
                  color='error'
                  onClick={() => navigate(`/editFeed/${pipeline.feed_id}`)}
                >{t('Hide feed execution')}</Button> */}
              </CardActions>


            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader
                title={'Feed Execution Details'}
                action={
                  <DownloadFileButton
                    url={`/download_pipeline_execution_details/${execution_id}`}
                    getToken={getToken} />
                }
              />
              <CardContent>
                Overview of the failed variable test definition and their amount:
                {aggregatedResults.map((row) => (
                  <p key={row.quality_test_instance_id}>
                    {assembleVariableTestName(row.variable_name,
                      row.multivariate_test_instance_id, row.quality_test_name)}: {row.execution_count}</p>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card>
              <MaterialTable
                title={t('Data Object List')}
                columns={
                  columnObject
                }
                data={dataObjects}
                onRowClick={(event, rowData) => {
                  getCellValue(rowData.data_entry_number);
                }}
                options={{
                  filtering: true,
                  search: false,
                  cellStyle: { padding: '0.3em'},
                  headerStyle: { padding: '0.3em'},
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50],
                  maxColumnSort: 1,
                  rowStyle: (rowData) => ({
                    backgroundColor: dataEntryNumber === rowData.data_entry_number ?
                      theme.palette.action.selected : theme.palette.background.paper,
                  }),
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={0} md={1}></Grid>
          {dataEntryNumber !== -1 ?
            <Grid item xs={12} md={6} key={`grid_${dataEntryNumber}`}>
              <Card key={`card_${dataEntryNumber}`}>
                <CardHeader
                  title={`Data Object ${dataEntryNumber}: Detail View`}
                />
                <CardContent>
                  <TableContainer>
                    {univariateTable}
                    {multivariateTable}
                  </TableContainer>
                </CardContent>
                <CardActions>
                  <Button size="small" disabled color='primary' variant="outlined">Deactivate Data Object</Button>
                  <Button size="small" disabled color='primary' variant="outlined">Manual Overwrite</Button>
                  <Button size="small" disabled color='primary' variant="outlined">Manual Correction</Button>
                </CardActions>
              </Card>
            </Grid> : <></>}
        </Grid>
      </main>
      <Footer />
    </>);
}

export default PipelineDetails;
