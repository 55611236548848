import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';

/**
 * Based on the execution status returns the icon in the right color
 * @param {bool & null} execution_successfull
 * @returns Material Icon with fitting color
 */
export const IconPicker = ({execution_successfull, sx=null, alert_type, testSuffix=''}) => {
  if (String(execution_successfull) === '1') {
    return (<CheckCircleIcon color="success" sx={sx} data-testid={`icon-success${testSuffix}`}/>);
  } else if (alert_type === "warning") {
    return (<ErrorIcon color="warning" sx={sx} data-testid={`icon-warning${testSuffix}`}/>);
  } else if (String(execution_successfull) === '0') {
    return (<CancelIcon color="error" sx={sx} data-testid={`icon-error${testSuffix}`}/>);
  } else if (String(execution_successfull) === '2') {
    return (<CancelIcon color="grey" sx={sx} data-testid={`icon-cancel${testSuffix}`}/>);
  } else {
    return (<LockOpenIcon color="grey" sx={sx} data-testid={`icon-lock${testSuffix}`}/>);
  }
};
