import { useContext, useState } from "react";
import { getNegativeUnixTimestamp } from "../utils/helperMethods";
import DomainContext from "../contexts/domainContext";

export default function useMultivariateTest(mvDescriptions, mvTests) {

  const [multivariateTests, setMultivariateTests] = useState(mvTests);
  const [multivariateTestDescriptions, setMultivariateDescriptions] = useState(mvDescriptions);

  const {qualityTests} = useContext(DomainContext);

  function updateMultivariateTestDescription (field, value) {
    const tempDescriptions = {...multivariateTestDescriptions};
    tempDescriptions[field] = value;
    setMultivariateDescriptions(tempDescriptions);
  }

  function addTest(test_type) {
    const newTest = {
      quality_test_id: '',
      quality_test_instance_id: getNegativeUnixTimestamp(),
      semantical_type_id: '',
      technical_type_id: '',
      test_type: test_type,
      variable_id: '',
      test_parameter: [],
      alert_type: 'warning',
    };

    setMultivariateTests(a => [...a, newTest]);
  }

  /**
   * Removes the test with given id
   * from the feedTests arrray.
   * @param {integer} id
   */
  function removeTest(id) {

    const index = getIndex(id);

    setMultivariateTests(instance => (
      [
        ...instance.slice(0, index),
        ...instance.slice(index + 1, instance.length)
      ]
    ));
  }

  function updateVariable (id, variable_id, semantical_type_id, technical_type_id) {
    const index = getIndex(id);

    const updatedTestInstance = multivariateTests[index];

    updatedTestInstance.variable_id = variable_id;
    updatedTestInstance.semantical_type_id = semantical_type_id;
    updatedTestInstance.technical_type_id = technical_type_id;

    // Check if the selected quality test is compatible with the new semantic and technical type
    if (updatedTestInstance.quality_test_id) {
      const qualityTest = qualityTests.find(item =>
        item.quality_test_id === updatedTestInstance.quality_test_id);

      if (!qualityTest.valid_semantical_types.includes(semantical_type_id) ||
        !qualityTest.valid_technical_types.includes(technical_type_id)
      ) {
        // Resetting the quality_test
        updatedTestInstance.quality_test_id = '';
        updatedTestInstance.test_parameter = [];
      }
    }

    // Update the state
    setMultivariateTests(a => [
      ...a.slice(0, index),
      updatedTestInstance,
      ...a.slice(index + 1, a.length),
    ]);

  }

  function updateTest (id, attribute, value) {

    const index = getIndex(id);

    const updatedTestInstance = multivariateTests[index];
    updatedTestInstance[attribute] = value;

    // Handle the parameter update
    if (attribute === 'quality_test_id') {
      const qualityTest = qualityTests.find(item =>
        item.quality_test_id === value);
      const parameterValuePlaceholder = qualityTest.quality_test_parameter.map(test => {
        return {parameter_value: '', quality_test_parameter_id: test.quality_test_parameter_id};
      });

      updatedTestInstance.test_parameter = parameterValuePlaceholder;
    }

    // Update the state
    setMultivariateTests(a => [
      ...a.slice(0, index),
      updatedTestInstance,
      ...a.slice(index + 1, a.length),
    ]);
  }

  function updateTestParameter(id, quality_test_parameter_id, value) {

    const index = getIndex(id);

    const newTestParameter = multivariateTests[index].test_parameter.map(
      parameter => parameter.quality_test_parameter_id === quality_test_parameter_id ?
        {...parameter, parameter_value: value} : parameter
    );

    updateTest(id, 'test_parameter', newTestParameter);
  }

  function returnMultivariateTests () {

    return {
      ...multivariateTestDescriptions,
      quality_tests: multivariateTests
    };
  }

  function getIndex (id) {
    return multivariateTests.findIndex(
      (test) => test.quality_test_instance_id === id);
  }

  return { multivariateTests,
    setMultivariateTests,
    multivariateTestDescriptions,
    setMultivariateDescriptions,
    updateMultivariateTestDescription,
    addTest,
    updateTest, updateTestParameter,
    removeTest,
    updateVariable,
    returnMultivariateTests,
  };


}
