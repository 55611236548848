/**
 * To avoid duplicate temporary keys which are also distinguishable from existing keys
 * Negative Unix Timestamps are used, as they are practically unique as the creation of one
 * takes long enough to avoid creating duplicate timestamps
 * The negative prefix allows the distinguishing from existing primary database keys which
 * are set to be positive numbers.
 * @returns Unix Timestamp * -1
 */
function getNegativeUnixTimestamp() {
  return Date.now() * -1;
}

export {getNegativeUnixTimestamp};
