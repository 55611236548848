import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar, Button, CircularProgress, Dialog, DialogContent,
  DialogTitle, List, ListItem, ListItemAvatar, ListItemText, MenuItem, TextField
} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import AuthContext from '../contexts/authContext';
import { useFetch } from '../hooks/useFetch';
import { addCollaborator, revokeCollaborator, updateCollaborator } from '../serverCommunication/serverCommunication';

const permissionLevels = [
  { value: 'owner', label: "Owner" },
  { value: 'edit', label: "Editor" },
  { value: 'view', label: "Viewer" },
];
const permissionLevelsRevoke = [
  { value: 'owner', label: "Owner" },
  { value: 'edit', label: "Editor" },
  { value: 'view', label: "Viewer" },
  { value: 'revoke', label: "Revoke" },
];


const renderCollaborators = (collaborator, removeCollaborator, handleUpdateCollaborator, ownUserId) => {

  let dropdownValue = 'view';

  if (collaborator?.owner) {
    dropdownValue = 'owner';
  } else if (collaborator?.edit) {
    dropdownValue = 'edit';
  }
  const handlePermissionChange = (event) => {
    if (event.target.value === 'revoke') {
      removeCollaborator(collaborator.user_id);
    } else {
      handleUpdateCollaborator(collaborator.user_id, event.target.value);
    }
  };

  return (
    <ListItem key={collaborator.user_id} secondaryAction={
      <TextField
        id="outlined-edit-permission"
        select
        disabled={collaborator.user_id === ownUserId}
        sx={{ width: '100px' }}
        label="Permissions"
        value={dropdownValue}
        onChange={handlePermissionChange}
        variant='standard'
      >
        {permissionLevelsRevoke.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    }
    >
      <ListItemAvatar>
        <Avatar>
          <ImageIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={
        (collaborator?.first_name !== null) ? `${collaborator.first_name} ${collaborator.last_name}` :
          "<No name>"} secondary={collaborator?.email} />


    </ListItem>

  );
};

export const ShareFeedDialog = (
  { open, setOpen, feedId }
) => {

  const { getToken, getUserId } = useContext(AuthContext);

  const [errorMsg, setErrorMsg] = useState('');
  const [collaborators, setCollaborators] = useState([]);
  const [newCollaborator, setNewCollaborator] = useState('');
  const [newCollaboratorPermission, setNewCollaboratorPermission] = useState('view');
  // Handle the loading server communication
  const { data: collas, loading } = useFetch('/get_collaborators/' + feedId, [], 'GET');

  useEffect(() => {
    setCollaborators(collas);
  }, [collas]);

  const handleClose = () => {
    setOpen(false);
  };

  const insertCollaborator = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    addCollaborator(
      getToken(), feedId, newCollaborator, newCollaboratorPermission,
      setCollaborators, setErrorMsg, setNewCollaborator, setNewCollaboratorPermission);
  };

  const removeCollaborator = (userId) => {
    revokeCollaborator(getToken(), userId, feedId, setCollaborators);
  };

  const handleUpdateCollaborator = (userId, permission) => {
    updateCollaborator(getToken(), userId, feedId, permission, collaborators, setCollaborators);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'md'}
    // fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        {`Share feed`}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={insertCollaborator}>
          {errorMsg !== '' ? <p>{errorMsg}</p> : <></>}
          <TextField id="outlined-basic" label="Email" variant="outlined"
            value={newCollaborator}
            onChange={(event) => setNewCollaborator(event.target.value)}
            sx={{
              marginRight: '10px',
              width: '350px',
              marginTop: '5px'
            }} />
          <TextField
            id="outlined-select-currency"
            select
            sx={{ marginRight: '10px', marginTop: '5px' }}
            label="Permissions"
            value={newCollaboratorPermission}
            onChange={(event) => setNewCollaboratorPermission(event.target.value)}

            helperText="Select the permissions"
          >
            {permissionLevels.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <Button sx={{
            height: '56px', marginTop: '5px'
          }} variant='outlined' type='submit'>Add</Button>
        </form>

        Collaborators
        {loading ? <CircularProgress size="lg" /> :

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {collaborators.map((collaborator, index) =>
              renderCollaborators(collaborator, removeCollaborator, handleUpdateCollaborator, getUserId())
            )}
          </List>
        }

      </DialogContent>

    </Dialog>
  );
};
