import React, { useEffect, useState, useContext } from 'react';
import Footer from './components/footer';
import NavbarApp from './components/navbar';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import { CardActions, CardContent,
  CardHeader, Grid, IconButton, List, ListItem,
  ListItemAvatar, ListItemButton,
  ListItemText, Tooltip, Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFetch } from './hooks/useFetch';
import { SkeletonCardEntryLoader } from './components/skeletonCardEntryLoader';
import { MissingFeedCardComponent, RenderCardAvatar } from './components/missingFeedCardComponent';
import { deleteEntry } from './serverCommunication/serverCommunication';
import AuthContext from './contexts/authContext';
import { useQuery } from '@tanstack/react-query';
import { createHeader } from './serverCommunication/communicationHelper';


const FeedCardComponent = ({title, feed, type, loading, hideEntry}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card kye={type}>
      <CardHeader title={title} />
      {loading ?
        <>
          <SkeletonCardEntryLoader />
          <SkeletonCardEntryLoader />
        </>
        :
        <List sx={{ width: '100%' }}>
          {feed && feed.map((row, count) => {
            if (count > 6) {
              return null;
            }
            return (
              <ListItem
                key={row.execution_result_id}
                disablePadding
                alignItems="flex-start"
                secondaryAction={
                  <>
                    {/* <Tooltip title="Successful/Total">
                      <Chip
                        variant="outlined"
                        style={{cursor: 'pointer'}}
                        label={
                          `${row.data_object_count - row.warning_count}/${
                            row.data_object_count !== null ? row.data_object_count : 0}`} />
                    </Tooltip> */}
                    {type !== 'open' ?
                      <Tooltip
                        data-testid={`hide_feed_execution${row.feed_id}`}
                        title="Hide feed execution">
                        <IconButton
                          edge="end" aria-label="hide-feed-execution"
                          onClick={() => hideEntry(row.execution_result_id)}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip> : <></>
                    }
                  </>
                }
              >
                <ListItemButton
                  onClick={() =>
                    navigate(`/pipelineDetails/${row.execution_result_id}`)}
                >
                  <ListItemAvatar>
                    <RenderCardAvatar type={type} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={row.reference_label != null ?
                      row.feed_name + ": " + row.reference_label :
                      row.feed_name
                    }
                    secondary={<React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                      >
                        {t('intlDateTime',
                          { val: (row.execution_close_time) ?
                            new Date(row.execution_close_time) : new Date(row.execution_create_time)})}
                      </Typography>
                    </React.Fragment>} />
                </ListItemButton>
              </ListItem>
            );
          }) }
          {(feed && feed.length === 0) ?
            <CardContent>
              {`There are no ${type} feeds.`}
            </CardContent> : <></>
          }
        </List>
      }
      <CardActions>
        <Button size="small" color='primary' onClick={() => navigate(`/pipelines`, {state: type})}>View all</Button>
      </CardActions>
    </Card>
  );
};


function Home() {

  const { t } = useTranslation();
  const { getToken } = useContext(AuthContext);

  const [feeds, setFeeds] = useState([]);

  const [openStreamingFeeds, setOpenStreamingFeeds] = useState([]);
  const [missingFeeds, setMissingFeeds] = useState([]);

  // Request the last 20 feed executions
  // const {data: feedData, loading: finishedExecutionLoading} = useFetch('/request_pipeline_results/0/20');
  const { status: finishedExecutionLoading, data: feedData } = useQuery({
    queryKey: ['pipeline_results'],
    queryFn: async () => {
      const response = await fetch('/request_pipeline_results/0/20', {
        method: 'POST',
        headers: createHeader(getToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });


  const {
    data: openStreamingFeedData, loading: finishedStreamingExecutionLoading
  } = useFetch('/request_pipeline_open');

  const {
    data: missingFeedNotifications, loading: missingFeedLoading
  } = useFetch('/request_missing_feed_notification');

  useEffect(() => {
    if (feedData) {
      setFeeds(feedData?.data);
    }
  }, [feedData]);

  useEffect(() => {
    if (missingFeedNotifications) {
      setMissingFeeds(missingFeedNotifications);
    }
  }, [missingFeedNotifications]);

  useEffect(() => {
    setOpenStreamingFeeds(openStreamingFeedData);
  }, [openStreamingFeedData]);

  const handleMissingFeedDismiss = (missing_feed_notification_id) => {
    deleteEntry(
      getToken(),
      `/missing_feed_notifications/${missing_feed_notification_id}`,
      () => setMissingFeeds(notification =>
        notification.filter(a => a.missing_feed_notification_id !== missing_feed_notification_id))
    );
  };

  const hideEntry = (execution_result_id) => {
    deleteEntry(
      getToken(),
      `/feed_execution/${execution_result_id}`,
      () => setFeeds(feed => feed.filter(a => a.execution_result_id !== execution_result_id))
    );
  };

  return (
    <>
      <NavbarApp />
      <main>
        <Grid
          container
          spacing={2}
          direction='row'
        >
          {/* <Grid item xs={12}>
            <Card>
              <CardHeader title={'Feed execution overview'}
                action={<Link to="/feeds"><Button variant="contained">Feed definition</Button></Link>}
              />
            </Card>

          </Grid> */}
          <Grid item xs={3}>
            <FeedCardComponent
              title={'Successful Feeds'}
              feed={feeds.filter(a => a.execution_successfull === '1')}
              type={'success'}
              hideEntry={hideEntry}
              loading={finishedExecutionLoading !== 'success'} />
          </Grid>
          <Grid item xs={3}>
            <FeedCardComponent
              title={t('Unsuccessful Feeds')}
              feed={feeds.filter(a => a.execution_successfull === '0')}
              type={'fail'}
              hideEntry={hideEntry}
              loading={finishedExecutionLoading !== 'success'} />

          </Grid>
          <Grid item xs={3}>
            <FeedCardComponent
              title={'Ongoing Streaming Feeds'}
              feed={openStreamingFeeds}
              type={'open'}
              loading={finishedStreamingExecutionLoading} />
          </Grid>

          <Grid item xs={3}>
            <MissingFeedCardComponent title={'Missing expected feeds'}
              feeds={missingFeeds}
              type={'missing'}
              loading={missingFeedLoading}
              handleMissingFeedDismiss={handleMissingFeedDismiss} />
          </Grid>

        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default Home;
