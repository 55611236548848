import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { Avatar,
  CardContent, CardHeader,
  Chip,
  Collapse,
  IconButton, List, ListItem,
  ListItemAvatar, ListItemButton,
  ListItemText, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SkeletonCardEntryLoader } from './skeletonCardEntryLoader';

export const RenderCardAvatar = ({type}) => {
  if (type === 'success') {
    return (<Avatar sx={{ bgcolor: 'green' }} aria-label="success">
      <CheckIcon />
    </Avatar>);
  } else if (type === 'fail') {
    return (<Avatar sx={{ bgcolor: 'error.main' }} aria-label="error">
      <CloseIcon />
    </Avatar>);
  } else if (type === 'open') {
    return (<Avatar sx={{ bgcolor: 'gray' }} aria-label="open">
      <LockOpenIcon />
    </Avatar>);
  } else {
    return (
      <Avatar sx={{ bgcolor: 'error.main' }} aria-label="missing">
        <PriorityHighIcon />
      </Avatar>
    );
  }
};

const RenderMissingFeedListItem = ({feedNotifications, handleMissingFeedDismiss, type}) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const row = feedNotifications[0];

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        key={`${row.feed_id}_${row.feed_expected_date}`}
        data-testid={`missing_feed_entry_${row.feed_id}`}
        disablePadding
        alignItems="flex-start"
        secondaryAction={
          (feedNotifications.length > 1 ?
            <Tooltip title="Amount notifications">
              <Chip
                variant="outlined"
                style={{cursor: 'pointer'}}
                label={feedNotifications.length} />
            </Tooltip> :
            <Tooltip
              data-testid={`dismiss_notification_${row.feed_id}`}
              title="Dismiss notification">
              <IconButton onClick={() => handleMissingFeedDismiss(row.missing_feed_notification_id)}
                edge="end" aria-label="dismiss-notification">
                <CloseIcon />
              </IconButton>
            </Tooltip>)
        }
      >
        <ListItemButton
          data-testid={`missing_feed_entry_button_${row.feed_id}`}
          onClick={feedNotifications.length > 1 ? () => handleClick() : () => navigate(`/editFeed/${row.feed_id}`)}
        >
          <ListItemAvatar>
            <RenderCardAvatar type={type} />
          </ListItemAvatar>
          <ListItemText
            primary={row.feed_name}
            secondary={<React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
              >
                {`Due: ${t('intlDate',
                  { val: new Date(row.feed_expected_date)})}
                  - ${t(row.execution_time_name)}
                `}
              </Typography>
            </React.Fragment>} />
          {
            feedNotifications.length > 1 ?
              open ? <ExpandLess /> : <ExpandMore /> :
              <></>
          }
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {feedNotifications.map((notification, index) => (
            <ListItem
              key={`${notification.missing_feed_notification_id}`}
              data-testid={`${notification.missing_feed_notification_id}`}
              sx={{ pl: 4 }}
              disablePadding
              secondaryAction={
                <Tooltip title="Dismiss notification"
                  data-testid={`dismiss_detail_notification_${notification.missing_feed_notification_id}`}>
                  <IconButton onClick={() => handleMissingFeedDismiss(notification.missing_feed_notification_id)}
                    edge="end" aria-label="close-notification">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              }
            >
              <ListItemButton onClick={() => navigate(`/editFeed/${row.feed_id}`)}>
                <ListItemText
                  primary={notification.feed_name}
                  secondary={<React.Fragment>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                    >
                      {`Due: ${t('intlDate',
                        { val: new Date(notification.feed_expected_date)})}
                      `}
                    </Typography>
                  </React.Fragment>} />
              </ListItemButton>
            </ListItem>)
          )}
        </List>
      </Collapse>
    </>
  );
};

export const MissingFeedCardComponent = ({title,
  feeds, type, loading, handleMissingFeedDismiss}
) => {

  // Prepare the data and transform it into a grouped map
  const groupedNotifications = feeds.reduce(
    (entryMap, e) => entryMap.set(e.feed_id, [...entryMap.get(e.feed_id)||[], e]),
    new Map()
  );

  return (
    <Card key={type}>
      <CardHeader title={title} />
      {loading ?
        <>
          <SkeletonCardEntryLoader />
          <SkeletonCardEntryLoader />
        </>
        :
        <List sx={{ width: '100%' }}>
          {feeds && [...groupedNotifications.keys()].map((notificationKey) => {
            // Access the map at the key
            const feedNotifications = groupedNotifications.get(notificationKey);

            return (
              <RenderMissingFeedListItem
                key={`${feedNotifications[0].feed_id}_${feedNotifications[0].feed_expected_date}`}
                feedNotifications={feedNotifications}
                handleMissingFeedDismiss={handleMissingFeedDismiss}
                type={type} />
            );
          }) }
          {(feeds && feeds.length === 0) ?
            <CardContent>
              {`There are no ${type} feeds.`}
            </CardContent> : <></>
          }
        </List>
      }
    </Card>
  );
};
