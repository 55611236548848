import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'; //OPTIONAL

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //  Set default language
    lng: "en",
    //  language to use if translations in user language are not available
    fallbackLng: "de",
    debug: false,
    //  string or array of namespaces to load
    ns: "translation",
    //  default namespace used if not passed to 'translation function'
    defaultNS: "translation",

    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        lng = 'de';
        if (format === "date") {
          const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
          return new Intl.DateTimeFormat(lng, options).format(value);
        } else if (format === 'date_time') {
          const options = { year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric' };
          return Intl.DateTimeFormat(lng, options).format(value);
        }
        return value;
      },
    },
  });

export default i18n;
