import React, { useContext, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import MaterialTable from '@material-table/core';
import { Grid } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';

import BreadCrumbComponent from '../../components/breadCrumbComponent';
import FeedExecutionTableContext from './FeedExecutionTableContext';
import { useQuery } from '@tanstack/react-query';
import { createHeader } from '../../serverCommunication/communicationHelper';
import AuthContext from '../../contexts/authContext';


function FeedExecutionOverview() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {getToken} = useContext(AuthContext);

  const [feedExecutions, setFeedExecutions] = useState([]);

  const { status, data: feedExecutionResult } = useQuery({
    queryKey: ['pipeline_results'],
    queryFn: async () => {
      const response = await fetch('/request_pipeline_results', {
        method: 'POST',
        headers: createHeader(getToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  useEffect(() => {
    setFeedExecutions(feedExecutionResult?.data);
  }, [feedExecutionResult]);

  const {columnObject} = useContext(FeedExecutionTableContext);

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}]}
          currentRoute={'Resolution'} />
        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={12}>
            <Card>
              <MaterialTable
                title={t('Feed Execution Overview')}
                columns={columnObject}
                data={feedExecutions}
                isLoading={status !== 'success'}
                onRowClick={(event, rowData) => {
                  navigate(`/pipelineDetails/${rowData.execution_result_id}`);
                }}
                options={{
                  filtering: true,
                  cellStyle: { padding: '0.3em'},
                  headerStyle: { padding: '0.3em'},
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50],
                }}
              />
            </Card>
          </Grid>
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default FeedExecutionOverview;
