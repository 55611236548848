import React, { useEffect, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import DataObjectIcon from '@mui/icons-material/DataObject';
import DataArrayIcon from '@mui/icons-material/DataArray';


export const TagInputTextfield = ({ ...props }) => {
  const { value, setValue, suggestedTags, ...other } = props;
  const [inputValue, setInputValue] = useState();
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    setSelectedItem([]);
    let passedValue = value;
    while (passedValue.includes('{') || passedValue.includes('[')) {
      const curlyIndex = passedValue.indexOf('{');
      const bracketIndex = passedValue.indexOf('[');

      let firstElement;
      if (curlyIndex !== -1 && (bracketIndex === -1 || curlyIndex < bracketIndex)) {
        firstElement = [passedValue.slice(0, curlyIndex + 1), passedValue.slice(curlyIndex + 1)];
      } else {
        firstElement = [passedValue.slice(0, bracketIndex + 1), passedValue.slice(bracketIndex + 1)];
      }
      passedValue = firstElement[1];
      setSelectedItem(elements => [...elements, firstElement[0]]);
    }
    setInputValue(passedValue);
  }, [value]);

  useEffect(() => {
    if (inputValue !== undefined) {
      setValue(selectedItem, inputValue);
    }
  }, [selectedItem, inputValue, setValue]);

  function handleInputChange(event) {
    // Here we need to handle if a dot is entered
    if (event.target.value.includes('{')) {
      // event.preventDefault();
      // event.stopPropagation();
      // Cut away the dot
      const tagStrings = event.target.value.split('{');
      const tagString = tagStrings[0] + '{';
      if (!tagString.replace(/\s/g, "").length) {return; }

      const newSelectedItem = [...selectedItem];
      newSelectedItem.push(tagString.trim());

      setSelectedItem(newSelectedItem);
      setInputValue(tagStrings[1]);
    } else if (event.target.value.includes('[')) {
      // event.preventDefault();
      // event.stopPropagation();
      // Cut away the dot
      const tagStrings = event.target.value.split('[');
      const tagString = tagStrings[0] + '[';
      if (!tagString.replace(/\s/g, "").length) {return; }

      const newSelectedItem = [...selectedItem];
      newSelectedItem.push(tagString.trim());

      setSelectedItem(newSelectedItem);
      setInputValue(tagStrings[1]);
    } else {
      setInputValue(event.target.value);
    }
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
  };


  return (
    <Autocomplete
      multiple
      sx={{mb: 2}}
      id="tags-filled"
      options={suggestedTags}
      getOptionLabel={(option) => option}
      freeSolo
      onChange={(event, newValue) => setSelectedItem(newValue)}
      value={selectedItem}
      // renderTags={(value, getTagProps) =>
      //   value.map((option, index) => (
      //     <Chip
      //       key={`${option}-${index}`}
      //       variant="outlined"
      //       label={option}

      //     />
      //   ))
      // }
      renderInput={(params) => {
        params.inputProps.value=inputValue;
        const { onBlur, onFocus, ...inputProps } = params.inputProps;
        return (
          <div ref={params.InputProps.ref}>
            <TextField
              ref={params.InputProps.ref}
              InputProps={{
                startAdornment: selectedItem.map((item, index) => {
                  let itemText = item;
                  let itemType = 'curly';
                  if (item.includes('{')) {
                    itemText = item.replace('{', '');
                  } else if (item.includes('[')) {
                    itemText = item.replace('[', '');
                    itemType = 'bracket';
                  }
                  return (
                    <Chip
                      icon={(itemType === 'curly' ? <DataObjectIcon /> : <DataArrayIcon />)}
                      data-testid={`${item}-${index}`}
                      key={`${item}-${index}`}
                      sx={{marginRight: '0.25em'}}
                      tabIndex={-1}
                      label={itemText}
                      variant='outlined'
                      color='primary'
                      onDelete={handleDelete(item)}
                    />);
                }),
                onChange: event => {
                  handleInputChange(event);
                },
                onFocus,
                onBlur,
              }}
              variant="filled"
              label="freeSolo"
              placeholder="Favorites"
              value={inputValue}
              onChange={handleInputChange}
              {...other}
              {...inputProps}
            />
          </div>
        );
      }
      }
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          // Prevent's default 'Enter' behavior.
          event.defaultMuiPrevented = true;
          // your handler code
        }
        if (
          selectedItem.length &&
            !inputValue.length &&
            event.key === "Backspace"
        ) {
          // Converting the chip back to a string
          const lastItem = selectedItem[selectedItem.length -1];
          setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
          setInputValue(lastItem + ' ');

        } else if (event.key === "Backspace") {
          // Prevents the default behaviour of mui autocomplete input
          event.defaultMuiPrevented = true;
        }
      }}
    />
  );
};
