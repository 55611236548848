import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function VariableConfirmationDialog({open, setOpen, executeFunction, variable, multiVariateTests}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid='type-change-dialog'
    >
      <DialogTitle id="alert-dialog-title">
        {"Confirm variable deactivation?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If the variable is deactived, the following connected
          multivariant tests
        </DialogContentText>
        <ul>
          {multiVariateTests.map(function(name, idx) {
            return (<li key={idx}>{name}</li>);
          })}
        </ul>
        <DialogContentText id="alert-dialog-description">
          are also going to be deactivated.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color='error' onClick={() => {handleClose(); executeFunction(variable.variable_id); }} autoFocus>
          Deactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
