import { checkAuthenticated, createHeader } from "./communicationHelper";

async function requestPipelineDetails(token, execution_id, entry_number, setPipelineDetails,
  setMvTestsResults) {
  return new Promise((resolve, reject) => {
    fetch(`/request_pipeline_details/${execution_id}/${entry_number}`, {
      method: 'POST',
      headers: createHeader(token),
    }).then(res => res.json())
      .then(
        (result) => {
          checkAuthenticated(result);
          setPipelineDetails(result.pipelineDetails);
          result.mvTestsResults.map(element => {
            let effectTestFound = false;
            for (const el of element.multivariate_test_results_values) {
              if (el.test_type === "effect_test") {
                effectTestFound = true;
                if (el.passed === 0) {
                  element.passed = '0'; element.reason = `(${el.test_type})`; return null;
                }
              }

            }
            if (effectTestFound) {
              element.reason = "";
              element.passed = '1';
              return null;
            } else {
              element.passed = '0'; element.reason = `(condition_test)`; return null;
            }
          });
          setMvTestsResults(result.mvTestsResults);
          resolve(result);
        },
        (error) => {
          console.error(error);
          reject(undefined);
        }
      );
  });
}


export {
  requestPipelineDetails,
};
