import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ContinueWithoutSavingDialog({open, setOpen, executeFunction}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid='type-change-dialog'
    >
      <DialogTitle id="alert-dialog-title">
        {"Continue without saving?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Some changes to the current variable are not yet saved.
          When you continue without saving those changes will be deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => {handleClose(); executeFunction(); }} autoFocus>
          Continue without saving
        </Button>
      </DialogActions>
    </Dialog>
  );
}
