import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import {QualityTestDropdown, AlertDropdown} from '../../components/qualityTestDropdown';
import QualityTestParameterInput from '../../components/qualityTestParameterInput';
import DomainContext from '../../contexts/domainContext';
import { getNegativeUnixTimestamp } from '../../utils/helperMethods';
import { HelpIcon } from '../../components/helpIcon';


export default function QualityTestDialog({
  open, closeDialog, qualityCheckInstance,
  changeVariable, domainTables,
  addCheck, updateCheck, disabledChanges
}) {

  const {qualityTests} = useContext(DomainContext);

  const [selectedCheck, setSelectedCheck] = useState('');
  const [testDescription, setTestDescription] = useState('');
  const [qualityCheckParameter, setQualityCheckParameter] = useState([]);

  const handleClose = () => {
    setQualityCheckParameter([]);
    setSelectedCheck('');
    setTestDescription('');
    closeDialog();
  };

  useEffect(() => {
    setSelectedCheck(qualityCheckInstance);
    if (qualityCheckInstance.quality_test_instance_id !== 0) {
      const checkId = qualityCheckInstance.quality_test_id;
      const qualityCheckDefinition = qualityTests.find(item =>
        item.quality_test_id === checkId);

      setTestDescription(qualityCheckDefinition.quality_test_description);
      setQualityCheckParameter(qualityCheckDefinition.quality_test_parameter);
    }

  }, [qualityTests, qualityCheckInstance]);

  const updateSelectedCheckAttribute = (attribute, value) => {
    const tempSelectedCheck = selectedCheck;
    tempSelectedCheck[attribute] = value;
    setSelectedCheck({...tempSelectedCheck});
  };

  const selectTest = (checkId) => {
    const qualityTest = qualityTests.find(item =>
      item.quality_test_id === checkId);
    setTestDescription(qualityTest.quality_test_description);
    setQualityCheckParameter(qualityTest.quality_test_parameter);
    const parameterValuePlaceholder = qualityTest.quality_test_parameter.map(test => {
      return {parameter_value: '', quality_test_parameter_id: test.quality_test_parameter_id};
    });

    updateSelectedCheckAttribute('quality_test_id', checkId);
    updateSelectedCheckAttribute('test_parameter', parameterValuePlaceholder);

  };

  const changeAlertType = (alert_type) => {
    updateSelectedCheckAttribute('alert_type', alert_type);
  };

  const updateTestParameterValue = (value, quality_test_parameter_id) => {
    const updatedData = selectedCheck.test_parameter.map(
      x => (x.quality_test_parameter_id === quality_test_parameter_id ? { ...x, parameter_value: value } : x));

    updateSelectedCheckAttribute('test_parameter', updatedData);
  };

  const storeResults = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    if (qualityCheckInstance.quality_test_instance_id === 0) {
      selectedCheck.quality_test_instance_id = getNegativeUnixTimestamp();
      addCheck(selectedCheck);
    } else {
      updateCheck(selectedCheck);
    }
    handleClose();
  };

  return (
    <Dialog data-testid='quality-check-dialog' open={open} onClose={handleClose}>
      <DialogTitle>Quality Check</DialogTitle>
      {selectedCheck !== '' ?
        <form onSubmit={storeResults}>
          <DialogContent>
            <DialogContentText style={{marginBottom: 2}}>
            Select a quality test for the selected variable.
            </DialogContentText>

            <QualityTestDropdown
              qualityTests={qualityTests}
              technical_type_id={changeVariable.technical_type_id}
              semantical_type_id={changeVariable.semantical_type_id}
              selectedTestId={selectedCheck.quality_test_id}
              disabled={qualityCheckInstance.quality_test_instance_id !== 0}
              setter={selectTest}
            />
            {selectedCheck.quality_test_id === 24 ?
              <HelpIcon
                message={`
                      The regular expression test follows the python flavor.\n
                      Quick overview:\n
                      Metacharacters:
                      [] \t\t A set of characters
                      \\ \t\t Signals a special sequence (can also be used to escape special characters)
                      . \t\t Any character (except newline character)
                      ^ \t\t Starts with
                      $ \t\t Ends with
                      * \t\t Zero or more occurrences
                      + \t\t One or more occurrences
                      ? \t\t Zero or one occurrences
                      {} \t\t Exactly the specified number of occurrences
                      | \t\t Either or
                      () \t\t Capture and group\n
                      Special Sequences:
                      \\A \t\t Returns a match if the specified characters are at the beginning of the string
                      \\b \t\t Returns a match where the specified characters are at the beginning or at the end
                      \t\t\t of a word (the "r" in the beginning is making sure that the string is being treated
                      \t\t\t as a "raw string")
                      \\B \t\t Returns a match where the specified characters are present, but NOT at the beginning
                      \t\t\t (or at the end) of a word (the "r" in the beginning is making sure that the string is
                      \t\t\t being treated as a "raw string")
                      \\d \t\t Returns a match where the string contains digits (numbers from 0-9)
                      \\D \t\t Returns a match where the string DOES NOT contain digits
                      \\s \t\t Returns a match where the string contains a white space characte
                      \\S \t\t Returns a match where the string DOES NOT contain a white space character
                      \\w \t\t Returns a match where the string contains any word characters (characters from a to Z,
                      \t\t\t digits from 0-9, and the underscore _ character)
                      \\W \t\t Returns a match where the string DOES NOT contain any word characters
                      \\Z \t\t Returns a match if the specified characters are at the end of the string\n
                      Sets:
                      [arn] \t\t Returns a match where one of the specified characters (a, r, or n) is present
                      [a-n] \t\t Returns a match for any lower case character, alphabetically between a and n
                      [^arn] \t Returns a match for any character EXCEPT a, r, and n
                      [0123] \t Returns a match where any of the specified digits (0, 1, 2, or 3) are present
                      [0-9] \t\t Returns a match for any digit between 0 and 9
                      [0-5][0-9] \t Returns a match for any two-digit numbers from 00 and 59
                      [a-zA-Z] \t Returns a match for any character alphabetically between a and z,
                      \t\t\t lower case OR upper case
                      [+] \t\t In sets, +, *, ., |, (), $,{} has no special meaning, so [+] means: return a
                      \t\t\t match for any + character in the string\n
                      Flags:
                      ASCII \t\t Make \\w, \\W, \\b, \\B, \\d, \\D, \\s and \\S perform ASCII-only matching instead
                      \t\t\t\t of full Unicode matching. This is only meaningful for Unicode patterns, and is
                      \t\t\t\t ignored for byte patterns.\n
                      IGNORECASE \t Perform case-insensitive matching; expressions like [A-Z] will also match
                      \t\t\t\t lowercase letters. Full Unicode matching (such as Ü matching ü) also works.\n
                      LOCALE \t\t Make \\w, \\W, \\b, \\B and case-insensitive matching dependent on the current
                      \t\t\t\t locale. This flag can be used only with bytes patterns. The use of this flag is
                      \t\t\t\t discouraged as the locale mechanism is very unreliable, it only handles one “culture”
                      \t\t\t\t at a time, and it only works with 8-bit locales. Unicode matching is already enabled
                      \t\t\t\t by default in Python 3 for Unicode (str) patterns, and it is able to handle different
                      \t\t\t\t locales/languages.\n
                      MULTILINE \t When specified, the pattern character '^' matches at the beginning of the string
                      \t\t\t\t and at the beginning of each line (immediately following each newline); and the pattern
                      \t\t\t\t character '$' matches at the end of the string and at the end of each line (immediately
                      \t\t\t\t preceding each newline). By default, '^' matches only at the beginning of the string,
                      \t\t\t\t and '$' only at the end of the string and immediately before the newline (if any) at
                      \t\t\t\t the end of the string.\n
                      DOTALL \t\t Make the '.' special character match any character at all, including a newline;
                      \t\t\t\t without this flag, '.' will match anything except a newline.
                      `
                }
                size="large"
                sx={{mb: -4.5}} /> : <></>
            }
            {selectedCheck.quality_test_id === 25 ?
              <HelpIcon
                message={`
                      A valid URL according to this test needs to have one of the following formats:\n
                      \t[Protocol]://[domain][:port]
                      \tSupported Protocols: http(s), ftp(s)
                      \tDomain: Either a domain in the format [domain].[top-level-domain]
                      \t\tOR: an IP-Address x.x.x.x
                      \t\tOR: "localhost"
                      \tPort: Optional Portnumber
                      `
                }
                size="large"
                sx={{mb: -4.5}} /> : <></>
            }
            <Typography>{testDescription}</Typography>
            {qualityCheckParameter.map((parameter, i) => (
              <QualityTestParameterInput
                parameter={parameter}
                testParameterValues={selectedCheck.test_parameter}
                index={i}
                disabled={disabledChanges}
                variant="standard"
                fullWidth={true}
                key={`qualityTestParameterHandler-${i}`}
                onChange={updateTestParameterValue}
                variableTechnicalTypeId={changeVariable.technical_type_id}
                domainTables={domainTables}
              />
            ))}
            <AlertDropdown
              selectedAlert={selectedCheck.alert_type}
              setter={changeAlertType}
              disabled={disabledChanges}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={selectedCheck.quality_test_id === '' || disabledChanges} type='submit'>Update</Button>
          </DialogActions>
        </form>
        : <></>}
    </Dialog>

  );
}
