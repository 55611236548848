import { createHeader } from "./communicationHelper";
// based on
// eslint-disable-next-line max-len
// https://christosmonogios.com/2022/05/10/Use-the-Fetch-API-to-download-files-with-their-original-filename-and-the-proper-way-to-catch-server-errors/


async function downloadFile(
  token, url, setLoading) {
  let filename = '';
  const timestamp = new Date();
  setLoading(true);
  await fetch(url, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({offset: timestamp.getTimezoneOffset()})
  })
    // This returns a promise inside of which we are checking for errors from the server.
    // The catch promise at the end of the call does not getting called when the server returns an error.
    // More information about the error catching can be found here:
    // https://www.tjvantoll.com/2015/09/13/fetch-and-errors/.
    .then((result) => {
      if (!result.ok) {
        throw Error(result.statusText);
      }

      // We are reading the *Content-Disposition* header for getting the original filename given from the server
      const header = result.headers.get('Content-Disposition');
      const parts = header.split(';');
      filename = parts[1].split('=')[1].replaceAll("\"", "");

      return result.blob();
    })
  // We use the download property for triggering the download of the file from our browser.
  // More information about the following code can be found
  // here: https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch.
  // The filename from the first promise is used as name of the file.
    .then((blob) => {
      if (blob != null) {
        const urlObj = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlObj;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setLoading(false);
      }
    })
  // The catch is getting called only for client-side errors.
  // For example the throw in the first then-promise, which is the error that came from the server.
    .catch((err) => {
      console.error(err);
    });
}

export {
  downloadFile,
};
