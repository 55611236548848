import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

const SkeletonTableLoader = ({columns, rows}) => {

  const RenderRow = ({cols, keyValue}) => {
    return (
      <TableRow key={`row ${keyValue}`}>
        {[...Array(cols)].map((x, i) =>
          <TableCell key={`Cell ${i}`}><Skeleton key={`Skeleton ${i}`} /></TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <TableBody>
      {[...Array(rows)].map((x, i) =>
        <RenderRow cols={columns} keyValue={i} key={i}/>
      )}
    </TableBody>
  );
};

export default SkeletonTableLoader;
