import React, { useContext, useEffect, useState } from 'react';
import Footer from './components/footer';
import NavbarApp from './components/navbar';
import Card from '@mui/material/Card';

import { Button, CardActions,
  CardContent, CardHeader,
  Checkbox,
  Divider, FormControlLabel, Grid, IconButton,
  MenuItem, TextField, Tooltip } from '@mui/material';
import AuthContext from './contexts/authContext';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  insertUpdateFeed, requestFeedInformation,
} from './serverCommunication/serverCommunication';
import { useTranslation } from 'react-i18next';
import {HelpIcon} from './components/helpIcon';
import DeleteIcon from '@mui/icons-material/Delete';


import BreadCrumbComponent from './components/breadCrumbComponent';
import DomainContext from './contexts/domainContext';
import { useFetch } from './hooks/useFetch';
import { FeedTestsComponent } from './components/feedTestComponent';


function CreateFeed() {

  const location = useLocation();
  const { getToken, getUserDetails } = useContext(AuthContext);
  const { t } = useTranslation();

  const {feedExecutionTimes} = useContext(DomainContext);


  const navigate = useNavigate();

  const [feed, setFeed] = useState({
    feed_name: '',
    feed_description: '',
    data_format_type_id: '1',
    execution_time_id: '',
    ignore_undefined_variables: false,
    owner: true,
    edit: false,
    view: false,
  });

  const [warningEmailRecipients, setWarningEmailRecipients] = useState(['']);

  // const [formats, setFormats] = useState([]);
  const [existingFeed, setExistingFeed] = useState(undefined);
  const [feedTestValues, setFeedTestValues] = useState([]);

  const {data: feedTestAttributes} = useFetch('/request_feed_test_attributes');

  let pathFeedId = -1;

  const pathName = location.pathname;
  if (pathName.includes('editFeed')) {
    pathFeedId = pathName.split("/")[pathName.split("/").length-1];
  }

  const {data: receivedFeedTests} = useFetch(`/request_feed_tests/${pathFeedId}`);

  const {data: formats} = useFetch('/request_feed_formats', []);

  useEffect(() => {
    if (pathFeedId !== -1) {
      setExistingFeed(true);
      requestFeedInformation(getToken(), pathFeedId, setFeed, setWarningEmailRecipients);
    } else {
      // If it is a new feed add the creators email address as feed warning receiver
      setWarningEmailRecipients([getUserDetails().email]);
      setExistingFeed(false);
    }
  }, [pathFeedId, getToken, getUserDetails, location]);

  const continueClick = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    // Pass the email addresses combined via comma
    // Not adding them via setFeed as the runtime could be
    // problematic
    // Insert/Update the feed object

    // Delete permissions from feed
    delete feed['owner'];
    delete feed['edit'];
    delete feed['view'];

    insertUpdateFeed(getToken(), feed, warningEmailRecipients.join(), feedTestValues,
      navigateToCreateVariable);
  };

  const navigateToCreateVariable = (feed_id) => {
    navigate(`/createVariable/${feed_id}`);
  };

  const goBack = () => {
    navigate('/feeds');
  };

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}, {route: 'Feeds', link: '/feeds'}]}
          currentRoute={existingFeed ? t('Edit Feed') : t('Create Feed')}
          nextPath={
            existingFeed ?
              [
                { route: 'Feed Variables', link: `/createVariable/${pathFeedId}` },
                { route: 'Multivariate Tests', link: `/multivariateTests/${pathFeedId}` }]
              : []} />
        <form onSubmit={continueClick}>
          <Grid
            container
            spacing={2}
            direction='row'
          >

            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title={
                    existingFeed ? t('Edit Feed') : t('Create Feed')
                  }/>
                <CardContent>
                  <TextField
                    sx={{mr: 2}}
                    required
                    disabled={!feed.owner && !feed.edit}
                    id="feed-name"
                    label="Feed Name"
                    variant="outlined"
                    value={feed.feed_name}
                    onChange={(event => setFeed({ ...feed, feed_name: event.target.value }))} />

                  <TextField
                    id="outlined-select-format"
                    sx={{mr: 2}}
                    select
                    required
                    disabled={true}
                    label="Select"
                    value={feed.data_format_type_id}
                    onChange={(event => setFeed({...feed, data_format_type_id: event.target.value}))}
                    helperText="Please select the format"
                  >
                    {formats && formats.map((option) => (
                      <MenuItem key={option.format_domain_id} value={option.format_domain_id}>
                        {option.format_domain_name}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    sx={{mb: 2}}
                    fullWidth
                    multiline
                    disabled={!feed.owner && !feed.edit}
                    id="feed-description"
                    label="Feed Description"
                    variant="outlined"
                    value={feed.feed_description}
                    onChange={(event => setFeed({...feed, feed_description: event.target.value}))} />

                  <TextField
                    id="outlined-select-feed-execution-time"
                    sx={{mr: 2}}
                    select
                    required
                    disabled={!feed.owner && !feed.edit}
                    label="Please select"
                    value={feed.execution_time_id}
                    onChange={(event => setFeed({...feed, execution_time_id: event.target.value}))}
                    helperText="Feed execution frequency"
                  >
                    {feedExecutionTimes.map((option) => (
                      <MenuItem key={option.execution_time_id} value={option.execution_time_id}>
                        {t(option.execution_time_name)}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormControlLabel control={
                    <Checkbox
                      checked={Boolean(feed.ignore_undefined_variables)}
                      disabled={!feed.owner && !feed.edit}
                      onChange={(event => setFeed({...feed,
                        ignore_undefined_variables: event.target.checked
                      }))}
                    />} label={
                    <><>Ignore undefined variables</>
                      <HelpIcon
                        message={
                          'Feeds with variables which are not defined in the feed variables are rejected otherwise.'
                        }
                        sx={{mb: 0}} /></>
                  } />


                  <Divider sx={{mb: 2, mt: 1}}/>

                  {warningEmailRecipients.map((email, index) =>

                    <div key={`recipient-${index}`}>
                      <TextField
                        sx={{mb: 2}}
                        style={{width: 330}}
                        disabled={!feed.owner && !feed.edit}
                        id={`feed-warning-recipients-${index}`}
                        name={`email-warning-recipients-${index}`}
                        label={`Email address ${index + 1}`}
                        helperText="Feed warning receiver"
                        variant="outlined"
                        inputProps={{type: 'email'}}
                        value={email}
                        required
                        onChange={(event =>
                          setWarningEmailRecipients(a => [
                            ...a.slice(0, index),
                            event.target.value,
                            ...a.slice(index + 1, a.length),
                          ])

                        )}
                      />
                      <Tooltip title="Delete">
                        <IconButton color="error" aria-label="delete"
                          onClick={() =>
                            setWarningEmailRecipients(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)])
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <br />
                    </div>
                  )}
                  <Button variant='outlined'
                    onClick={() => setWarningEmailRecipients(recipients => [...recipients, ''])}
                  >Add recipient</Button>

                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    color='secondary'
                    onClick={() => goBack()}>Cancel</Button>
                  <Button
                    size="small"
                    type="submit">{'Save & Continue'}</Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <FeedTestsComponent feedTestAttributes={feedTestAttributes}
                receivedFeedTests={receivedFeedTests}
                setFeedTestValues={setFeedTestValues}
                existingFeed={existingFeed}
                disabledChanges={(!feed.owner && !feed.edit)}
              />
            </Grid>
          </Grid>
        </form>
      </main>
      <Footer />
    </>
  );
}

export default CreateFeed;
