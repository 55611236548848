import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from 'react';

export const ConfirmDeletionDialog = (
  {open, setOpen, element, executionFunction}
) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`Do you want to deactivate the selected ${element}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`The deactivation of a ${element} is irreversible
           and can lead to the deletion of previous feed executions
          `}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={executionFunction} autoFocus>
          {`Deactivate ${element}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
