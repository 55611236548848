import {jwtDecode} from 'jwt-decode';

const getToken = () => localStorage.getItem('token');
const setToken = token => { localStorage.setItem('token', token); };
const isTokenExpired = token => jwtDecode(token).exp < Date.now() / 1000;

const loggedIn = () => {
  // Check if there is a saved token and whether it is still valid
  const token = getToken();
  return !!token && !isTokenExpired(token);
};

const logoutHelper = () => {
  localStorage.removeItem('token');
  if (window.location.pathname !== '/') {
    window.location.href = '/';
  }
};

const getDecodedToken = () => jwtDecode(getToken());

const getLoggedInUserId = () => getDecodedToken().id;

const hasPermission = permission => getDecodedToken().permissions.includes(permission);

export { getToken,
  setToken, isTokenExpired, loggedIn, logoutHelper,
  getDecodedToken, getLoggedInUserId, hasPermission };
