import { useContext, useState } from "react";
import { getNegativeUnixTimestamp } from "../utils/helperMethods";
import DomainContext from "../contexts/domainContext";

export default function useFeedTests(defaultValue) {
  const {qualityTests} = useContext(DomainContext);
  const [feedTests, setFeedTests] = useState(defaultValue);
  const [updated, setUpdated] = useState(false); // Stores whether a change has been made

  /**
   * Creates an empty test and appends to feedTests
   * @param {integer} feed_id
   */
  function addTest(feed_id) {

    const newTest = {
      feed_test_instance_id: getNegativeUnixTimestamp(),
      feed_id: feed_id,
      feed_test_attribute_id: '',
      quality_test_id: '',
      semantical_type_id: '',
      technical_type_id: '',
      test_parameter: [],
    };
    setFeedTests(a => [...a, newTest]);
    setUpdated(true);
  }

  function setDatabaseFeedTests(dbFeedTests) {
    setFeedTests(dbFeedTests);
    setUpdated(true);
  }

  /**
   * Removes the test with given index
   * from the feedTests arrray.
   * @param {integer} index
   */
  function removeTest(index) {

    setFeedTests(instance => (
      [
        ...instance.slice(0, index),
        ...instance.slice(index + 1, instance.length)
      ]
    ));
  }

  function updateTest(index, attribute, value) {

    const updatedTestInstance = feedTests[index];
    updatedTestInstance[attribute] = value;

    if (attribute === 'quality_test_id') {
      const qualityTest = qualityTests.find(item =>
        item.quality_test_id === value);
      const parameterValuePlaceholder = qualityTest.quality_test_parameter.map(test => {
        return {parameter_value: '', quality_test_parameter_id: test.quality_test_parameter_id};
      });
      updatedTestInstance.test_parameter = parameterValuePlaceholder;
    }
    setFeedTests(instance => (
      [
        ...instance.slice(0, index),
        updatedTestInstance,
        ...instance.slice(index + 1, instance.length)
      ]
    ));
  }

  function updateTestParameter(index, quality_test_parameter_id, value) {

    const newTestParameter = feedTests[index].test_parameter.map(
      parameter => parameter.quality_test_parameter_id === quality_test_parameter_id ?
        {...parameter, parameter_value: value} : parameter
    );

    updateTest(index, 'test_parameter', newTestParameter);
  }

  /**
   * Sets the default feed test that there are no
   * failed data objects allowed for feed execution.
   */
  function setDefaultFeedTest() {
    const newTest = {
      feed_test_instance_id: getNegativeUnixTimestamp(),
      feed_id: null,
      feed_test_attribute_id: 2,
      quality_test_id: 18,
      semantical_type_id: 3,
      technical_type_id: 3,
      test_parameter: [
        {quality_test_parameter_id: 10, parameter_value: 0}
      ],
    };

    setFeedTests(a => [...a, newTest]);
    setUpdated(true);
  }

  return { feedTests, addTest,
    removeTest, updateTest, updateTestParameter,
    setDatabaseFeedTests, setDefaultFeedTest,
    updated,
  };

}
