import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardActions,
  CardContent, CardHeader,
  Checkbox, Chip, Divider,
  FormControlLabel, MenuItem,
  TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { HelpIcon } from '../../components/helpIcon';
import { useTranslation } from 'react-i18next';
import QualityTestDialog from './qualityCheckDialog';
import TypeChangeDialog from './TypeChangeDialog';
import DomainContext from '../../contexts/domainContext';
import { TagInputTextfield } from './TagInputTextfield';

export const EditVariableCard = ({
  currentVariable,
  domainTables,
  suggestedTags,
  storeVariable,
  checkDeleteVariable,
  closeEditView,
  variableSaved,
  setVariableSaved,
  otherVariables,
  disabledChanges
}) => {
  const { t } = useTranslation();
  const [changeVariable, setChangeVariable] = useState(null);
  const [openQualityTestDialog, setOpenQualityTestDialog] = useState(false);
  // Change Variable Type Dialog
  const [openTypeChangeDialog, setOpenTypeChangeDialog] = useState(false);
  const [variableTypeUpdateFunction, setVariableTypeUpdateFunction] = useState();
  const [qualityCheckInstance, setQualityCheckInstance] = useState(null);

  const {semanticalTypeDomain, technicalTypeDomain, qualityTests} = useContext(DomainContext);

  useEffect(() => {
    setChangeVariable(currentVariable);
  }, [currentVariable]);

  function handlePrefixInput(items, variableName) {
    if (items.length === 0 && variableName !== changeVariable.variable_name) {
      setChangeVariable({...changeVariable,
        variable_name: variableName});
      setVariableSaved(false);
    }
    if (items.length > 0) {
      const combinedName = items.join('') + variableName;

      if (combinedName !== changeVariable.variable_name) {
        setChangeVariable({...changeVariable,
          variable_name: combinedName});
        setVariableSaved(false);
      }
    }
  }

  const submitForm = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    storeVariable(changeVariable);
  };

  // Handle updates to the quality checks of the current variable
  const deleteCheck = (quality_test_instance_id) => {
    // Get the index of the element for the id
    let tempChecks = changeVariable.quality_tests;
    tempChecks = tempChecks.filter(item =>
      item.quality_test_instance_id !== quality_test_instance_id);

    setChangeVariable({...changeVariable,
      quality_tests: tempChecks});
    setVariableSaved(false);
  };

  const addCheck = (newQualityCheckInstance) => {
    const usedChecks = changeVariable.quality_tests;
    usedChecks.push(newQualityCheckInstance);
    // update the current variable
    setChangeVariable({...changeVariable,
      quality_tests: usedChecks});
    setVariableSaved(false);
  };

  const updateCheck = (updatedQualityCheckInstance) => {
    const index = changeVariable.quality_tests.findIndex(item =>
      item.quality_test_instance_id === updatedQualityCheckInstance.quality_test_instance_id);
    const usedChecks = changeVariable.quality_tests;
    usedChecks[index] = updatedQualityCheckInstance;
    // update the current variable
    setChangeVariable({...changeVariable,
      quality_tests: usedChecks});
    setVariableSaved(false);
  };

  const handleTypeChange = (field, value) => {
    if (changeVariable.variable_id) {
      setVariableTypeUpdateFunction(() => () => {
        setVariableSaved(false);
        setChangeVariable({...changeVariable,
          [field]: value});
      });
      setOpenTypeChangeDialog(true);
    } else {
      setChangeVariable({...changeVariable,
        [field]: value});
      setVariableSaved(false);
    }
  };

  const sortQualityChecks = (a, b) => {
    return 0;
    // Currently not supported by the data structure
    // console.log(a, b)
    // if (a.type === b.type) {
    //   return 0;
    // } else if (a.type === 'technical') {
    //   return -1;
    // } else if (a.type === 'semantical' && b.type === 'technical') {
    //   return 1;
    // } else {
    //   return 0;
    // }
  };

  const handleChipColor = (check) => {

    if (!check.valid_semantical_types.includes(changeVariable.semantical_type_id) ||
      !check.valid_technical_types.includes(changeVariable.technical_type_id)) {
      return 'error';
    } else if (check.test_type === 'technical') {
      return 'primary';
    } else {
      return 'secondary';
    }
  };

  const invalidQTests = () => {
    return changeVariable?.quality_tests.some(testInstance => {
      const qualityCheckDefinition = qualityTests.find(item =>
        (item.quality_test_id === testInstance.quality_test_id
          && item.quality_test_id !== 11
          && item.quality_test_id !== 3
        ));
      if (qualityCheckDefinition === undefined) {
        return false;
      }
      return (!qualityCheckDefinition.valid_semantical_types.includes(changeVariable.semantical_type_id) ||
      !qualityCheckDefinition.valid_technical_types.includes(changeVariable.technical_type_id));
    });
  };

  // Handle the dialogs
  const closeQualityTestDialog = () => {
    setOpenQualityTestDialog(false);
    setQualityCheckInstance(null);
  };


  if (changeVariable !== null) {

    return (
      <>
        {qualityCheckInstance !== null ?
          <QualityTestDialog
            open={openQualityTestDialog}
            closeDialog={closeQualityTestDialog}
            qualityCheckInstance={qualityCheckInstance}
            changeVariable={changeVariable}
            domainTables={domainTables}
            setChangeVariable={setChangeVariable}
            addCheck={addCheck}
            updateCheck={updateCheck}
            disabledChanges={disabledChanges}
          /> : <></>
        }
        {changeVariable.variable_id ?
          <TypeChangeDialog
            open={openTypeChangeDialog}
            setOpen={setOpenTypeChangeDialog}
            executeFunction={variableTypeUpdateFunction}
          /> : <></>
        }

        <Card>
          <CardHeader
            title={changeVariable.variable_id ? `Edit Variable` : `New Variable`}
          />
          <form onSubmit={submitForm}>
            <CardContent>
              <TagInputTextfield
                value={currentVariable.variable_name}
                setValue={handlePrefixInput}
                suggestedTags={suggestedTags}
                fullWidth
                variant="outlined"
                id="tags"
                name="Variable Name"
                placeholder="Define Variablename"
                label={<><>Variable Name</>
                  <HelpIcon
                    message={'Identifier of the variable in the feeds sent to the API.\n' +
                      'List of values of the same type can be treated as a normal value. \n' +
                      'Please provide nested objects split with a { \n' +
                      'Arrays with nested objects is split with only a [ '
                    }
                    sx={{mb: 0}} /></>}
                required
                data-testid={`variable-name-input`}
                sx={{}}
                disabled={disabledChanges}
                helperText={
                  (otherVariables.some(e => e.variable_name === changeVariable.variable_name))
                    ? "Duplicate variable names are not permitted" : ''}
              />
              <TextField
                data-testid="outlined-select-semantical"
                id="outlined-select-semantical"
                sx={{mr: 2, mb: 2}}
                disabled={disabledChanges}
                select
                required
                label="Please select"
                value={changeVariable.semantical_type_id}
                onChange={(event) => handleTypeChange('semantical_type_id', event.target.value)}
                helperText="Semantic variable type"
              >
                {semanticalTypeDomain.map((option) => (
                  <MenuItem key={option.semantical_type_id} value={option.semantical_type_id}>
                    {option.semantical_type_name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                data-testid="outlined-select-technical"
                id="outlined-select-technical"
                sx={{mr: 2, mb: 2}}
                select
                disabled={disabledChanges}
                required
                label="Please select"
                value={changeVariable.technical_type_id}
                onChange={event => handleTypeChange('technical_type_id', event.target.value)}
                helperText="Technical variable type"
              >
                {technicalTypeDomain.map((option) => (
                  <MenuItem key={option.technical_type_id} value={option.technical_type_id}>
                    {option.technical_type_name}
                  </MenuItem>
                ))}
              </TextField>
              {changeVariable.technical_type_id === 3 ?
                <TextField
                  data-testid={`select-decimal-separator`}
                  sx={{mr: 2, mb: 2}}
                  select
                  disabled={disabledChanges}
                  required={changeVariable.technical_type_id === 3}
                  label="Please select"
                  value={changeVariable.decimal_separator}
                  onChange={
                    (event => {
                      setChangeVariable({...changeVariable,
                        decimal_separator: event.target.value});
                      setVariableSaved(false);
                    })
                  }
                  helperText="Decimal Separator"
                >
                  {[{value: ',', name: 'Comma'}, {value: '.', name: 'Decimal point'}].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField> : <></>
              }
              {changeVariable.technical_type_id === 6 ?
                <TextField
                  sx={{mr: 2, mb: 2}}
                  required
                  label={<><>Date Format</>
                    <HelpIcon
                      message={
                        <>
                          <>Enter the format of the Datetime string of the variable.</><br />
                          <>For example: "%Y-%m-%d %H:%M:%S" for a string like "2022-02-22 12:00:00".</><br />
                          <>More information about format directives:</><br />
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://docs.python.org/3/library/datetime.html#strftime-and-strptime-format-codes'>
                            Python Datetime Format Codes
                          </a>
                        </>
                      }
                      sx={{mb: 0}} /></>}
                  data-testid='date-format'
                  disabled={disabledChanges}
                  id="date-format"
                  variant="outlined"
                  value={changeVariable.date_format}
                  onChange={(event => {
                    setChangeVariable({...changeVariable,
                      date_format: event.target.value});
                    setVariableSaved(false);
                  })} /> : <></>
              }

              <br/>

              <FormControlLabel control={
                <Checkbox
                  disabled={disabledChanges}
                  checked={Boolean(changeVariable.variable_required)}
                  onChange={(event => {
                    setChangeVariable({...changeVariable,
                      variable_required: event.target.checked
                    });
                    setVariableSaved(false);
                  })}
                />} label={<><>{t('Variable required')}</>
                <HelpIcon
                  message={'Variable must be present in the feed.'}
                  sx={{mb: 0}} /></>} />

              {/* <FormControlLabel control={
                <Checkbox
                  checked={Boolean(changeVariable.missings_handling)}
                  onChange={(event => {
                    setChangeVariable({...changeVariable,
                      missings_handling: event.target.checked,
                      missings_encoding: ''
                    });
                    setVariableSaved(false);
                  })}
                />} label={<><>{t('MissingsAllowed')} </>
                <HelpIcon
                  message={
                    <>If missings are allowed this causes a warning.
                    If missings are not allowed this causes an error.</>
                  }
                  sx={{mb: 0}} /></>} /> */}
              <TextField
                data-testid="missings_handling"
                id="missings_handling"
                sx={{ mr: 2, mb: 2 }}
                select
                disabled={disabledChanges}
                required
                label="Please select"
                value={changeVariable.missings_handling}
                onChange={(event => {
                  setChangeVariable({
                    ...changeVariable,
                    missings_handling: event.target.value.toLowerCase(),
                    missings_encoding: ''
                  });
                  setVariableSaved(false);
                })}
                helperText={
                  <>Missings handling
                    <HelpIcon
                      message={
                        <>Allows to define how missing are handled, whether an error,
                          a warning or nothing is returned.
                          If info it will be only visible on the platform</>
                      }
                      sx={{ mb: 0 }} />
                  </>}
              >
                {
                  [
                    { value: 'error', name: 'Error' }, { value: 'warning', name: 'Warning' },
                    { value: 'info', name: 'Info' }
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
              <FormControlLabel control={
                <Checkbox
                  checked={Boolean(changeVariable.type_conversion)}
                  disabled={disabledChanges}
                  onChange={(event => {
                    setChangeVariable({...changeVariable,
                      type_conversion: event.target.checked,
                    });
                    setVariableSaved(false);
                  })}
                />} label={<><>{t('Type Conversion')}</>
                <HelpIcon
                  message={
                    <>If possible, converts automatically a wrong data type into the correct one.
                    The Is Data Type test always creates an error if the type is not correct
                    or it could not be converted.</>
                  }
                  sx={{mb: 0}} /></>}/>
              <br/>
              <br/>

              {/* {changeVariable.missings_allowed ?
                      <TextField
                        sx={{mr: 2}}
                        id="missings-encoding"
                        label="Individual Missing Encoding"
                        variant="outlined"
                        placeholder='e.g. None'
                        value={changeVariable.missings_encoding}
                        onChange={(event => setChangeVariable({...changeVariable,
                          missings_encoding: event.target.value}))} /> : <></>
                    } */}

              <TextField
                sx={{mr: 2, mb: 2}}
                required
                multiline
                fullWidth
                disabled={disabledChanges}
                data-testid='variable-description'
                id="variable-description"
                label="Variable Description"
                variant="outlined"
                value={changeVariable.variable_description}
                onChange={(event => {
                  setChangeVariable({...changeVariable,
                    variable_description: event.target.value});
                  setVariableSaved(false);
                })} />

              <Divider light />
              {/* <Typography sx={{mt: 1, mb: 1}} variant='h5'>Domain Table</Typography>
              <Button
                variant='outlined'
                disabled
                sx={{mt: 1, mb: 2}}
                onClick={() => {console.error("Button clicked");}}
              >
                      Add Domain Table
              </Button>
              <HelpIcon message={`List of allowed values for the variable.`} sx={{mb: 2, ml: 1 }}/>
              <Divider light /> */}
              <Typography sx={{mt: 1, mb: 1}} variant='h5'>Variable Tests</Typography>
              <Typography>The "Check Missing"/"Is Not Missing" and the "Is Data Type" test are always executed and
                      cannot be removed.
              </Typography>
              <Box sx={{mb: 1}}>
                <Chip
                  sx={{mr: 2, mt: 1}}
                  color={'primary'}
                  label={changeVariable.missings_handling !== 'error' ? 'Check Missing' : 'Is Not Missing'}
                />
                <Chip
                  sx={{mr: 2, mt: 1}}
                  color={'primary'}
                  label={'Is Data Type'}
                />
                {changeVariable.quality_tests.sort(sortQualityChecks).map((row, i) => {
                  const check = qualityTests.find(item => item.quality_test_id === row.quality_test_id);
                  if (check.quality_test_name === 'Check Missing' ||
                          check.quality_test_name === 'Is Data Type' || check.quality_test_name === 'Is Not Missing') {
                    return null;
                  }
                  return (
                    <Chip
                      sx={{mr: 2, mt: 1}}
                      data-testid={`${check.quality_test_name}-${i}`}
                      key={i}
                      color={handleChipColor(check)}
                      onClick={() => {
                        setOpenQualityTestDialog(true);
                        setQualityCheckInstance(row);
                      }}
                      onDelete={() => deleteCheck(row.quality_test_instance_id)}
                      label={
                        check.quality_test_name}
                    />);
                })}
              </Box>

              <Button
                variant='outlined'
                sx={{mt: 1}}
                disabled={
                  disabledChanges ||
                  changeVariable.semantical_type_id === '' ||
                        changeVariable.technical_type_id === ''}
                onClick={() => {
                  setOpenQualityTestDialog(true);
                  setQualityCheckInstance({
                    quality_test_id: '',
                    quality_test_instance_id: 0,
                    test_parameter: [],
                    alert_type: 'warning',
                  });
                }}
              >
                Add Quality Test
              </Button>


            </CardContent>
            <CardActions>
              <Button
                size="small" color='secondary'
                onClick={() => closeEditView()}>{t('Cancel')}</Button>
              {changeVariable?.variable_id ?
                <Button
                  disabled={disabledChanges}
                  size="small" color='error'
                  onClick={() => checkDeleteVariable(changeVariable.variable_id)}>{t('Deactivate')}</Button>
                : <></>
              }
              <Button
                size="small"
                type='submit'
                disabled={
                  disabledChanges ||
                  invalidQTests() ||
                  variableSaved ||
                  otherVariables.some(e => e.variable_name === changeVariable.variable_name)
                }
              >{t('Save')}</Button>
            </CardActions>
          </form>
        </Card>
      </>
    );
  } else {
    return null;
  }
};
