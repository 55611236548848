import * as React from 'react';
import Box from '@mui/material/Box';
import revalu_logo_white from '../assets/Revalu_Logo_Reverse_RGB.svg';


export default function Footer() {
  return (
    <footer
      data-testid="footer-id">
      <Box
        sx={{
          color: 'white',
          backgroundColor: 'primary.main',
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <div>
          <img src={revalu_logo_white} alt="revalu Logo" style={{ height: 20, margin: 10, color: 'white' }} />
        </div>
        <div data-testid="copyright">
          &copy; {new Date().getFullYear()} <b>revalu</b>. All Rights Reserved.
        </div>
        <div>
          {/* <Box sx={{
            float: {
              xs: 'none',
              sm: 'right'
            },
          }}>
            <Link href="https://acs-plus.de/de/impressum.html"
              underline="none"
              sx={{marginRight: 2}}
              target="_blank"
              rel="noopener"
              color="white">
              {'Impressum'}
            </Link>
            <Link href="https://acs-plus.de/de/datenschutz.html"
              underline="none" target="_blank" rel="noopener"
              color="white">
              {'Datenschutz'}
            </Link>
          </Box> */}
        </div>
      </Box>
    </footer>

  );

}
