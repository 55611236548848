import { checkAuthenticated, createHeader } from "./communicationHelper";

async function requestMultivariateTests(token, feedId, setMultivariateTests) {
  await fetch(`/request_multivariate_tests/${feedId}`, {
    method: 'POST',
    headers: createHeader(token),
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        setMultivariateTests(result.multivariate_tests);
      },
      (error) => {
        console.error(error);
      }
    );
}

async function insertUpdateMultivariateTest(
  token, newCopiedTest, multivariateTests, setMultivariateTests) {
  await fetch(`/insert_update_multivariate_test`, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      multivariate_test: newCopiedTest,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);

        const returnedMultivariateTest = result.multivariate_test;

        const index = multivariateTests.findIndex(
          mvTest => mvTest.multivariate_test_instance_id === returnedMultivariateTest.multivariate_test_instance_id
        );

        if (index !== -1) {
          setMultivariateTests(a => [
            ...a.slice(0, index),
            returnedMultivariateTest,
            ...a.slice(index + 1, a.length),
          ]);
        } else {
          setMultivariateTests(a => [...a, returnedMultivariateTest]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function deactivateMultivariateTest(token, multivariate_test_instance_id, setMultivariateTests) {
  await fetch(`/deactivate_multivariate_test/${multivariate_test_instance_id}`, {
    method: 'POST',
    headers: createHeader(token),
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setMultivariateTests(a => a.filter(
            e => e.multivariate_test_instance_id !== multivariate_test_instance_id)
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
}


export {
  requestMultivariateTests,
  insertUpdateMultivariateTest,
  deactivateMultivariateTest,
};
