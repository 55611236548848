import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { Card, Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import BreadCrumbComponent from '../../components/breadCrumbComponent';
import Footer from '../../components/footer';
import NavbarApp from '../../components/navbar';
import AuthContext from '../../contexts/authContext';
import { createHeader } from '../../serverCommunication/communicationHelper';
import { useTheme } from '@emotion/react';


function DomainTables() {

  const {getToken} = useContext(AuthContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();

  // Using a state on the columns to keep track of changes
  const [domainTableColumnObject, setdomainTableColumnObject] = useState([]);
  const [domainTableEntryColumnObject, setdomainTableEntryColumnObject] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedDomainTableId, setSelectedDomainTableId] = useState(-1);

  const { status, data: domainTables } = useQuery({
    queryKey: ['domainTables'],
    queryFn: async () => {
      const response = await fetch('/domain_tables', {
        method: 'GET',
        headers: createHeader(getToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  const { status: entryStatus, data: domainTableEntries } = useQuery({
    enabled: selectedDomainTableId !== -1,
    queryKey: ['domainTableEntry', selectedDomainTableId],
    queryFn: async () => {
      const response = await fetch(`/domain_tables/${selectedDomainTableId}/entries`, {
        method: 'GET',
        headers: createHeader(getToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
  });

  const createDomainTable = useMutation({
    mutationFn: async ({domain_table_name, domain_table_description}) => {
      const response = await fetch(`/domain_tables`, {
        method: 'POST',
        headers: createHeader(getToken()),
        body: JSON.stringify({domain_table_name, domain_table_description})
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
    onSuccess: async (data, error, variables, context) => {
      queryClient.setQueryData(['domainTables'], (old) => [...old, data]);
      queryClient.setQueryData(['domainTableEntry', data.domain_table_id], []);
      setSelectedDomainTableId(data.domain_table_id);
    },
  });

  const deleteDomainTable = useMutation({
    mutationFn: async (domain_table_id) => {
      const response = await fetch(`/domain_tables/${domain_table_id}`, {
        method: 'DELETE',
        headers: createHeader(getToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    },
    onSuccess: async (data, argument) => {
      queryClient.setQueryData(['domainTables'], (old) => old.filter(e => e.domain_table_id !== argument));
      if (selectedDomainTableId === argument) {
        setSelectedDomainTableId(-1);
      }
    },
  });

  const updateDomainTable = useMutation({
    mutationFn: async ({domain_table_name, domain_table_description, domain_table_id}) => {
      // console.log(index, domain_table_id)
      const response = await fetch(`/domain_tables/${domain_table_id}`, {
        method: 'PUT',
        headers: createHeader(getToken()),
        body: JSON.stringify({domain_table_name, domain_table_description})
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
    onSuccess: async (data, error, variables, context) => {
      queryClient.setQueryData(['domainTables'], (old) => {
        const index = old.findIndex(
          (domainTable) => domainTable.domain_table_id === data.domain_table_id);
        data.valid_from = old[index].valid_from;
        return [...old.slice(0, index),
          data,
          ...old.slice(index + 1, old.length)
        ];
      });
    },
  });


  // Domain Table entries
  const createDomainTableEntry = useMutation({
    mutationFn: async ({value, description}) => {
      const response = await fetch(`/domain_tables/${selectedDomainTableId}/entries`, {
        method: 'POST',
        headers: createHeader(getToken()),
        body: JSON.stringify([{value, description}])
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
    onSuccess: async (data, error, variables, context) => {
      queryClient.setQueryData(['domainTableEntry', selectedDomainTableId], (old) => [...old, data[0]]);
    },
  });

  const updateDomainTableEntry = useMutation({
    mutationFn: async ({value, description, domain_table_entry_id}) => {
      const response = await fetch(`/domain_tables/${selectedDomainTableId}/entries/${domain_table_entry_id}`, {
        method: 'PUT',
        headers: createHeader(getToken()),
        body: JSON.stringify({value, description})
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
    onSuccess: async (data, error, variables, context) => {
      queryClient.setQueryData(['domainTableEntry', selectedDomainTableId], (old) => {
        const index = old.findIndex(
          (domainTable) => domainTable.domain_table_entry_id === data.domain_table_entry_id);
        data.valid_from = old[index].valid_from;
        return [...old.slice(0, index),
          data,
          ...old.slice(index + 1, old.length)
        ];
      });
    },
  });

  const deleteDomainTableEntry = useMutation({
    mutationFn: async ({domain_table_entry_id}) => {
      const response = await fetch(`/domain_tables/${selectedDomainTableId}/entries/${domain_table_entry_id}`, {
        method: 'DELETE',
        headers: createHeader(getToken()),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    },
    onSuccess: async (data, argument, variables, context) => {
      queryClient.setQueryData(['domainTableEntry', selectedDomainTableId], (old) =>
        old.filter(e => e.domain_table_entry_id !== argument.domain_table_entry_id)
      );
    },
  });

  useEffect(() => {
    const tmpCols = [
      {
        title: 'ID',
        field: 'domain_table_id',
        type: 'numeric',
        editable: 'never',
      },
      {
        title: 'Name',
        type: 'string',
        field: 'domain_table_name',
        hidden: false,
      },
      {
        title: 'Description',
        type: 'string',
        field: 'domain_table_description',
        hidden: false,
      },
      {
        title: 'Created at',
        field: 'execution_time',
        type: 'date',
        defaultSort: 'desc',
        editable: 'never',
        render: rowData =>
          new Date(rowData.valid_from).toLocaleString(),
      },
    ];
    setdomainTableColumnObject(tmpCols);
  }, []);

  useEffect(() => {
    const tmpCols = [
      {
        title: 'Name',
        type: 'string',
        field: 'value',
        hidden: false,
      },
      {
        title: 'Description',
        type: 'string',
        field: 'description',
        hidden: false,
      },
      {
        title: 'Created at',
        field: 'execution_time',
        type: 'date',
        defaultSort: 'desc',
        editable: 'never',
        render: rowData =>
          new Date(rowData.valid_from).toLocaleString(),
      },
    ];
    setdomainTableEntryColumnObject(tmpCols);
  }, []);

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}]}
          currentRoute={'Domain Tables'} />
        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={6}>
            <Card>
              <MaterialTable
                title={t('Data Object List')}
                columns={
                  domainTableColumnObject
                }
                data={domainTables}
                isLoading={status !== 'success'}
                onRowClick={(event, rowData) => {
                  setSelectedIndex(rowData.tableData.index + 1);
                  setSelectedDomainTableId(rowData.domain_table_id);
                }}
                options={{
                  filtering: true,
                  search: false,
                  cellStyle: { padding: '0.3em'},
                  headerStyle: { padding: '0.3em'},
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50],
                  maxColumnSort: 1,
                  rowStyle: (rowData) => ({
                    backgroundColor: selectedIndex -1 === rowData.tableData.index ?
                      theme.palette.action.selected : theme.palette.background.paper,
                  }),
                }}
                editable={{
                  onRowAddCancelled: (rowData) => console.error("Row adding cancelled"),
                  onRowUpdateCancelled: (rowData) => console.error("Row editing cancelled"),
                  onRowAdd: (newData) => {
                    return new Promise((resolve, reject) => {
                      setTimeout(() => {
                        createDomainTable.mutate(newData);
                        resolve();
                      }, 1000);
                    });
                  },
                  onRowUpdate: (newData, oldData) => {
                    return new Promise((resolve, reject) => {
                      updateDomainTable.mutate(newData);
                      setTimeout(() => {
                        resolve();
                      }, 1000);
                    });
                  },
                  onRowDelete: (oldData) => {
                    return new Promise((resolve, reject) => {
                      deleteDomainTable.mutate(oldData.domain_table_id);
                      setTimeout(() => {
                        resolve();
                      }, 1000);
                    });
                  },
                }}
              />

            </Card>
          </Grid>
          {selectedDomainTableId !== -1 ?
            <Grid item xs={6}>
              <Card>
                <MaterialTable
                  title={t('Domain Table Entries')}
                  columns={
                    domainTableEntryColumnObject
                  }
                  data={domainTableEntries}
                  isLoading={entryStatus !== 'success'}
                  options={{
                    filtering: true,
                    search: false,
                    cellStyle: { padding: '0.3em'},
                    headerStyle: { padding: '0.3em'},
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    maxColumnSort: 1,
                  }}
                  editable={{
                    onRowAddCancelled: (rowData) => console.error("Row adding cancelled"),
                    onRowUpdateCancelled: (rowData) => console.error("Row editing cancelled"),
                    onRowAdd: (newData) => {
                      return new Promise((resolve, reject) => {
                        createDomainTableEntry.mutate(newData);
                        setTimeout(() => {
                          resolve();
                        }, 1000);
                      });
                    },
                    onRowUpdate: (newData, oldData) => {
                      return new Promise((resolve, reject) => {
                        setTimeout(() => {
                          updateDomainTableEntry.mutate(newData);
                          resolve();
                        }, 1000);
                      });
                    },
                    onRowDelete: (oldData) => {
                      return new Promise((resolve, reject) => {
                        deleteDomainTableEntry.mutate(oldData);
                        setTimeout(() => {
                          resolve();
                        }, 1000);
                      });
                    },
                  }}
                />

              </Card>
            </Grid> : <></> }
        </Grid>

      </main>
      <Footer />
    </>
  );
}

export default DomainTables;
