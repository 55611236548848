import React, { useContext } from 'react';

import { Grid } from '@mui/material';


import Footer from './components/footer';
import NavbarApp from './components/navbar';

import AuthContext from './contexts/authContext';
import FeedOverviewCard from './components/feedOverviewCard';
import BreadCrumbComponent from './components/breadCrumbComponent';

function FeedOverview() {

  const { getToken, getUserId } = useContext(AuthContext);

  return (
    <>
      <NavbarApp />
      <main>
        <BreadCrumbComponent
          path={[{route: 'Dashboard', link: '/'}]}
          currentRoute={'Feed Definitions'} />
        <Grid
          container
          spacing={2}
          direction='row'
        >
          <Grid item xs={12}>
            <FeedOverviewCard getToken={getToken} getUserId={getUserId} />
          </Grid>
        </Grid>
      </main>
      <Footer />
    </>
  );
}

export default FeedOverview;
