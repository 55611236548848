import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0F4DA8',
    },
    secondary: {
      main: '#FFA100',
    },
    error: {
      main: '#AB0000',
    },
    grey: {
      main: 'grey',
    }
  },
  // shadows: Array("none").fill(25),
});
