async function loginApiCall(email, password, stayLoggedIn, setToken, setLoginWarning) {
  await fetch('/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
      stayLoggedIn,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        // const resultContent = JSON.parse(result);
        if (result.msg === 'Login successfull') {
          setToken(result.token);
        } else {
          setLoginWarning(true);
        }
        return result;
      },
      (error) => {
        console.error(error);
        return undefined;
      }
    );
}

export {loginApiCall};
