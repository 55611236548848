/* eslint-disable react/jsx-filename-extension */
import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IconPicker } from "../../components/FeedExecutionIconPicker";
import { useFetch } from "../../hooks/useFetch";


const FeedExecutionTableContext = createContext();

export function FeedExecutionTableContextProvider({children}) {

  // Using a state on the columns to keep track of changes
  const [columnObject, setColumnObject] = useState([]);

  // Access the useLocation hook to access parameters
  const {state} = useLocation();

  const {data: feedData} = useFetch('/request_feeds', []);

  const [feedIdToName, setFeedIdToName] = useState({});
  const [defaultFilter, setDefaultFilter] = useState('');

  useEffect(() => {
    if (feedData) {
      setFeedIdToName(Object.fromEntries(feedData.map(feed =>
        [feed.feed_id, feed.feed_name])));
    }
  }, [feedData]);

  useEffect(() => {
    if (state === 'success') {
      setDefaultFilter('1');
    } else if (state === 'fail') {
      setDefaultFilter('0');
    } else if (state === 'open') {
      setDefaultFilter('open');
    }
  }, [state]);


  useEffect(() => {
    const tmpCols = [
      {
        title: 'Successful',
        field: 'execution_successfull',
        type: 'boolean',
        width: 100,
        render: rowData => <IconPicker execution_successfull={rowData.execution_successfull}/>,
        lookup: {
          // eslint-disable-next-line quote-props
          '1': 'Success', '0': 'Unsuccessful', 'open': 'Open'
        },
        defaultFilter: defaultFilter !== '' ? [defaultFilter] : null,
      },
      {
        title: 'Execution ID',
        field: 'execution_result_id',
        type: 'numeric',
        width: 150,
        defaultSort: 'desc',
      },
      {
        title: 'Feed',
        field: 'feed_id',
        lookup: feedIdToName,
      },
      {
        title: 'Reference Label',
        field: 'reference_label',
        type: 'string',
      },
      {
        title: 'Executed at',
        field: 'execution_time',
        type: 'date',
        cellStyle: {
          textAlign: "right"
        },
        // dateSetting: { locale: "de-DE" },
        // filterComponent: (props) => <CustomDatePicker {...props} />,
        render: rowData =>
          new Date(rowData.execution_time).toLocaleString(),
      },
      // {
      //   title: 'Passed/Total',
      //   field: 'object_count',
      //   filtering: false,
      //   render: rowData => (`${rowData.data_object_count - rowData.warning_count}/${
      //     rowData.data_object_count !== null ? rowData.data_object_count : 0}`),
      //   width: 100,
      // },
    ];
    setColumnObject(tmpCols);
  }, [feedIdToName, defaultFilter]);

  return (
    <FeedExecutionTableContext.Provider
      value={{columnObject}}>
      {children}
    </FeedExecutionTableContext.Provider>
  );
}

export default FeedExecutionTableContext;
