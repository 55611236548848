import { Button, Card, CardActions,
  CardContent, CardHeader, Divider, FormControl, FormControlLabel, FormLabel, List,
  Radio,
  RadioGroup,
  TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { RenderTest } from "./RenderTest";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from "react-i18next";
import useMultivariateTest from "../../hooks/useMultivariateTestHook";
import DomainContext from "../../contexts/domainContext";
import { useEffect } from "react";

const MultivariateTestsComponent = ({
  selectedQualityTests,
  variables,
  updateTest,
  updateTestParameter,
  removeTest,
  updateVariable,
  type,
  domainTables,
  disabledChanges
}) => {

  const {qualityTests} = useContext(DomainContext);

  return (
    <List>
      {selectedQualityTests.length > 0 ?
        selectedQualityTests.map((test, index) => {
          return (
            <RenderTest
              test={test}
              key={`${type}-test-${index}`}
              variables={variables}
              qualityTests={qualityTests}
              testParameterValues={test.test_parameter}
              updateMultivariateTests={updateTest}
              updateMultivariateTestParameters={updateTestParameter}
              removeTest={removeTest}
              updateVariable={updateVariable}
              type={type}
              index={index}
              domainTables={domainTables}
              disabledChanges={disabledChanges}
            />
          );

        }) : <Typography variant="h6">Please select at least one test.</Typography>

      }
    </List>
  );
};


const ManageMultivariateTest = ({
  selectedIndex, variables,
  multivariateTests,
  updateTest,
  updateTestParameter,
  addTest,
  removeTest,
  multivariateTestDescriptions,
  updateMultivariateTestDescription,
  updateVariable, domainTables, disabledChanges
}) => {

  const { t } = useTranslation();
  if (selectedIndex === -1) {
    return (<></>);
  }

  return (
    <>
      <Typography mb={2} variant="h6">Connect conditions for two or more variables</Typography>
      <TextField
        data-testid='multivariate-test-name'
        sx={{mr: 2, mb: 2}}
        required
        disabled={disabledChanges}
        id="Multivariate-test-name"
        label="Multivariate Test Name"
        variant="outlined"
        helperText={`${multivariateTestDescriptions.multivariate_test_name.length}/50`}
        value={multivariateTestDescriptions.multivariate_test_name}
        onChange={(event => updateMultivariateTestDescription('multivariate_test_name', event.target.value))}
        fullWidth
        inputProps={{
          maxLength: 50,
        }}
      />
      <TextField
        data-testid='multivariate-test-description'
        sx={{mr: 2, mb: 2}}
        disabled={disabledChanges}
        id={'mv-description'}
        label='Description'
        multiline
        value={multivariateTestDescriptions.multivariate_test_description}
        onChange={(event => updateMultivariateTestDescription('multivariate_test_description', event.target.value))}
        fullWidth
        required
      />
      <FormControl>
        <FormLabel id="radio-button-condition_connection">Conditions</FormLabel>
        <RadioGroup
          row
          value={multivariateTestDescriptions.condition_connection}
          onChange={(event => updateMultivariateTestDescription('condition_connection', event.target.value))}
          aria-labelledby="radio-button-condition_connection"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="or" disabled={disabledChanges} control={<Radio />} label="Or" />
          <FormControlLabel value="and" disabled={disabledChanges} control={<Radio />} label="And" />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="radio-button-effect_connection">Effects</FormLabel>
        <RadioGroup
          row
          value={multivariateTestDescriptions.effect_connection}
          onChange={(event => updateMultivariateTestDescription('effect_connection', event.target.value))}
          aria-labelledby="radio-button-effect_connection"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="or" disabled control={<Radio />} label="Or" />
          <FormControlLabel value="and" disabled control={<Radio />} label="And" />
        </RadioGroup>
      </FormControl>
      {/* <FormControl style={{ marginLeft: 30 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">Effects</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel value="or" control={<Radio />} label="Or" />
          <FormControlLabel value="and" control={<Radio />} label="And" />
        </RadioGroup>
      </FormControl> */}
      <Divider light />
      <Typography mb={2} mt={2} variant="h6">Conditions</Typography>
      <MultivariateTestsComponent
        selectedQualityTests={multivariateTests
          .filter(qTest => qTest.test_type === 'condition_test')}
        variables={variables}
        updateTest={updateTest}
        updateTestParameter={updateTestParameter}
        removeTest={removeTest}
        updateVariable={updateVariable}
        type={'condition'}
        domainTables={domainTables}
        disabledChanges={disabledChanges}
      />
      <Button
        disabled={disabledChanges}
        sx={{my: 2}} variant='outlined'
        onClick={() => addTest('condition_test')}
        startIcon={<AddCircleOutlineIcon />}>
          Add Condition
      </Button>


      <Divider light />
      <Typography mb={2} mt={2} variant="h6">{t('Effect')}</Typography>
      <MultivariateTestsComponent
        selectedQualityTests={multivariateTests
          .filter(qTest => qTest.test_type === 'effect_test')}
        variables={variables}
        updateTest={updateTest}
        updateTestParameter={updateTestParameter}
        removeTest={removeTest}
        updateVariable={updateVariable}
        type={'effect'}
        domainTables={domainTables}
        disabledChanges={disabledChanges}
      />
      <Button
        sx={{my: 2}}
        disabled={disabledChanges}
        variant='outlined'
        onClick={() => addTest('effect_test')}
        startIcon={<AddCircleOutlineIcon />}>Add Effect</Button>

    </>
  );
};

export const ManageMultivariateTestCard = ({
  selectedMultivariateTest, storeMultivariateTest,
  closeEditView, deactivateMultivariateTest,
  variables, selectedIndex, domainTables, disabledChanges
}) => {

  const { t } = useTranslation();

  const { multivariate_test_description,
    multivariate_test_instance_id,
    multivariate_test_name, condition_connection, effect_connection } = selectedMultivariateTest;

  const { multivariateTests,
    setMultivariateTests,
    addTest,
    updateTest, updateTestParameter,
    removeTest,
    multivariateTestDescriptions,
    setMultivariateDescriptions,
    updateMultivariateTestDescription,
    updateVariable,
    returnMultivariateTests,
  } = useMultivariateTest(
    {
      multivariate_test_description, multivariate_test_instance_id,
      multivariate_test_name, condition_connection, effect_connection
    },
    selectedMultivariateTest.quality_tests
  );

  const handleSave = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();
    storeMultivariateTest(returnMultivariateTests());
  };

  useEffect(() => {
    setMultivariateTests(selectedMultivariateTest.quality_tests);
    setMultivariateDescriptions({
      multivariate_test_description, multivariate_test_instance_id,
      multivariate_test_name, condition_connection, effect_connection
    });

  }, [selectedMultivariateTest, setMultivariateTests, setMultivariateDescriptions,
    multivariate_test_description, multivariate_test_instance_id, multivariate_test_name,
    condition_connection, effect_connection
  ]);

  return (
    <Card
      key={`multivariate-test-${selectedIndex}`}
      title='Multivariate Test'
      data-testid={`multivariate-test-${selectedIndex}`}
    >
      <CardHeader title='Multivariate Test'/>
      <form onSubmit={handleSave}>
        <CardContent>
          <ManageMultivariateTest
            selectedIndex={selectedIndex}
            variables={variables}
            multivariateTests={multivariateTests}
            multivariateTestDescriptions={multivariateTestDescriptions}
            updateMultivariateTestDescription={updateMultivariateTestDescription}
            updateTest={updateTest}
            updateTestParameter={updateTestParameter}
            addTest={addTest}
            removeTest={removeTest}
            updateVariable={updateVariable}
            domainTables={domainTables}
            disabledChanges={disabledChanges}
          />
        </CardContent>
        <CardActions>
          <Button
            size="small" color='secondary'
            onClick={() => closeEditView()}>{t('Cancel')}</Button>
          {selectedMultivariateTest?.multivariate_test_instance_id > 0 ?
            <Button
              disabled={disabledChanges}
              size="small" color='error'
              onClick={() => {
                deactivateMultivariateTest(selectedMultivariateTest.multivariate_test_instance_id);
              }}
            >{t('Deactivate')}</Button>
            : <></>
          }
          <Button
            disabled={
              disabledChanges ||
              multivariateTests
                .filter(qTest => qTest.test_type === 'effect_test').length === 0 ||
              multivariateTests
                .filter(qTest => qTest.test_type === 'condition_test').length === 0 }
            size="small"
            type='submit'
          >{t('Save')}</Button>
        </CardActions>
      </form>
    </Card>
  );
};
