import { logoutHelper } from "../components/authHelperMethods";


const createHeader = (token) => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
};

const checkAuthenticated = (result) => {
  if (result?.msg === 'Token has expired') {
    logoutHelper();
  }
};

export {createHeader, checkAuthenticated};
