import { Breadcrumbs, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from 'react';

const BreadCrumbComponent = ({ path, nextPath = [], currentRoute }) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      data-testid="bread-crumb-1"
      sx={{mb: '10px'}}>
      {path.map((row, i) => {
        return (
          <NavLink
            key={i}
            underline="hover"
            color="inherit"
            to={row.link}
          >
            {row.route}
          </NavLink>
        );
      })}
      <Typography color="text.primary">{currentRoute}</Typography>
      {nextPath.map((row, i) => {
        return (
          <NavLink
            key={i}
            underline="hover"
            color="inherit"
            to={row.link}
          >
            {row.route}
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbComponent;
