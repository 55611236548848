/* eslint-disable react/jsx-filename-extension */
import React, { createContext, useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch";

const DomainContext = createContext();

export function DomainProvider({children}) {

  const [feedExecutionTimes, setFeedExecutionTimes] = useState([]);
  const [semanticalTypeDomain, setSemanticalTypeDomain] = useState([]);
  const [technicalTypeDomain, setTechnicalTypeDomain] = useState([]);
  const [qualityTests, setQualityTests] = useState([]);
  const [reFlags, setReFlags] = useState([]);

  const {data: feedExecution} = useFetch('/request_feed_execution_times');
  const {data: semanticalTypes} = useFetch('/request_semantical_types');
  const {data: technicalTypes} = useFetch('/request_technical_types');
  const {data: qualityTestData} = useFetch('/request_quality_tests');
  const {data: reFlagsData} = useFetch('/request_re_flags');

  useEffect(() => {
    if (feedExecution) {
      setFeedExecutionTimes(feedExecution);
    }
  }, [feedExecution]);

  useEffect(() => {
    if (technicalTypes) {
      setTechnicalTypeDomain(technicalTypes);
    }
  }, [technicalTypes]);

  useEffect(() => {
    if (semanticalTypes) {
      setSemanticalTypeDomain(semanticalTypes);
    }
  }, [semanticalTypes]);

  useEffect(() => {
    if (qualityTestData) {
      setQualityTests(qualityTestData);
    }
  }, [qualityTestData]);

  useEffect(() => {
    if (reFlagsData) {
      // reFlagsData.unshift({flag_id: 0, flag_name: 'No flag'});
      setReFlags(reFlagsData);
    }
  }, [reFlagsData]);


  return (
    <DomainContext.Provider
      value={{feedExecutionTimes,
        semanticalTypeDomain,
        technicalTypeDomain,
        qualityTests,
        reFlags}}>
      {children}
    </DomainContext.Provider>
  );


}

export default DomainContext;
