import { Box, Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { downloadFile } from '../serverCommunication/downloadFile';

export const DownloadFileButton = (
  {url, getToken}
) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          disabled={downloadLoading}
          onClick={() => downloadFile(
            getToken(), url,
            setDownloadLoading
          )}
        >
          Download results
        </Button>
        {downloadLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </Box>
  );
};
