import { MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DomainContext from '../contexts/domainContext';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function DomainDropdown({
  domainTables, parameter, selectedDomainTableId, setter, sx={mr: 2, mb: 2},
}) {
  const { t } = useTranslation();
  return (
    <TextField
      data-testid={'select-domain-table'}
      sx={sx}
      select
      required
      inputProps={{
        id: parameter.parameter_name
      }}
      label={t(parameter.parameter_name)}
      value={selectedDomainTableId}
      onChange={(event) => setter(String(event.target.value), parameter.quality_test_parameter_id)}
      helperText="Please select a domain table"
    >
      {domainTables.map((entry) => {
        return (
          <MenuItem key={entry.domain_table_id} value={entry.domain_table_id}>
            {entry.domain_table_name}
          </MenuItem>);
      })}
    </TextField>
  );
}

function FlagDropdown({
  parameter, selectedFlagId, setter, sx = { mr: 2, mb: 2 }, disabled
}) {
  const { t } = useTranslation();
  const {reFlags} = useContext(DomainContext);
  return (
    <TextField
      data-testid={'select-flag'}
      sx={sx}
      select
      disabled={disabled}
      required
      inputProps={{
        id: parameter.parameter_name
      }}
      label={t(parameter.parameter_name)}
      value={selectedFlagId}
      onChange={(event) => setter(String(event.target.value), 16)}
      helperText="Please select a flag."
    >
      {reFlags.map((entry) => {
        return (
          <MenuItem key={entry.flag_id} value={entry.flag_id}>
            {entry.flag_name}
          </MenuItem>);
      })}
    </TextField>
  );
}

export default function QualityTestParameterInput({
  parameter, testParameterValues, index,
  sx={}, variant="outlined", fullWidth=false,
  onChange, variableTechnicalType, domainTables, disabled
}) {

  const { t } = useTranslation();
  const [parameterValue, setParameterValue] = useState('');

  useEffect(() => {
    if (testParameterValues) {
      const testParameterValue = testParameterValues.find(
        values => values.quality_test_parameter_id === parameter.quality_test_parameter_id
      );
      if (testParameterValue) {
        setParameterValue(testParameterValue.parameter_value);
      }
    }
  }, [testParameterValues, parameter]);


  const handleInputType = () => {
    if (variableTechnicalType === 'Integer') {
      return 'number';
    } else if (variableTechnicalType === 'Float') {
      return 'number';
    } else if (variableTechnicalType === 'Unix Timestamp') {
      return 'number';
    } else if (variableTechnicalType === 'Datetime') {
      return 'date';
    } else if (variableTechnicalType === 'String') {
      return 'text';
    } else {
      return 'text';
    }
  };

  const handleMin = () => {
    if (variableTechnicalType === 'Integer' || variableTechnicalType === 'Float') {
      return 0;
    } else {
      return "";
    }
  };

  const handleStep = () => {
    if (variableTechnicalType === 'Integer') {
      return 1;
    } else if (variableTechnicalType === 'Float') {
      return 0.1;
    } else {
      return "";
    }
  };

  // To use only UTC in the datetime picker, we need to convert input and output of datetime picker
  // Source: https://stackoverflow.com/questions/72103096/mui-x-date-pickers-utc-always-please
  const getTimezoneOffset = value => value.getTimezoneOffset() * 60000;

  const makeLocalAppearUTC = value => {
    if (value) {
      const dateTime = new Date(value);
      const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
      return utcFromLocal;
    } else {
      return value;
    }
  };

  const localToUTC = dateTime => {
    if (dateTime) {
      const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
      return utcFromLocal;
    } else {
      return dateTime;
    }
  };

  if (parameter.quality_test_parameter_id === 5 || parameter.quality_test_parameter_id === 6) {
    return (
      <DomainDropdown
        disabled={disabled}
        domainTables={domainTables}
        parameter={parameter}
        selectedDomainTableId={parameterValue}
        setter={onChange}
        sx={sx}
      />
    );
  } else if (parameter.quality_test_parameter_id === 16) {
    return (
      <FlagDropdown
        parameter={parameter}
        disabled={disabled}
        selectedFlagId={parameterValue}
        setter={onChange}
        sx={sx}
      />
    );
  } else if (handleInputType(parameter.parameter_type) === 'date') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          required
          disabled={disabled}
          renderInput={(props) => <TextField {...props}
            helperText="Note: If only date relevant, then set time to 12:00 PM." />}
          label="Datetime"
          value={makeLocalAppearUTC(parameterValue)}
          onChange={(newVal) => { onChange(localToUTC(newVal), parameter.quality_test_parameter_id); }}
          sx={sx}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <TextField
        data-testid={`quality-test-parameter-input-${index}`}
        sx={sx}
        disabled={disabled}
        aria-label="quality-test-parameter-input"
        key={`qualityTestParameterInput-${index}`}
        required
        margin="dense"
        id={parameter.parameter_name}
        type={(handleInputType())}
        label={t(parameter.parameter_name)}
        value={parameterValue}
        variant={variant}
        fullWidth={fullWidth}
        helperText="Please add the test parameter"
        onChange={(event) => onChange(event.target.value, parameter.quality_test_parameter_id)}
        InputProps={{ inputProps: { min: handleMin(), step: handleStep() } }}
      />
    );
  }
}
