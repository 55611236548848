import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import { useNavigate, NavLink } from "react-router-dom";
import AuthContext from "../contexts/authContext";

const pages = [
  // {title: 'Dashboard', route: '/home'},
  {title: 'Resolution', route: '/pipelines'},
  {title: 'Configurator', route: '/feeds'},
  // {title: 'Supervised Corrector', route: '/corrections', disabled: true},
  {title: 'Domain Tables', route: '/domainTables'},
];

const Navbar = () => {
  const navigate = useNavigate();
  const { authed, logout, isAdmin } = React.useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <NavLink className="nav-header-quality" color="inherit" to={'/home'}>
              QUAL-ET CONTROL CENTER
            </NavLink>

          </Typography>
          {authed ?
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map(({title, route, disabled}) => (
                  <MenuItem key={title} onClick={handleCloseNavMenu} disabled={disabled}>
                    <Typography textAlign="center">
                      <NavLink className="nav-header-dropdown" color="inherit" to={route}>
                        {title}
                      </NavLink>
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> : <></> }

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <NavLink className="nav-header" color="inherit" to={'/home'}>
              QUAL-ET CONTROL CENTER
            </NavLink>
          </Typography>

          {authed ?
            <>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map(({title, route, disabled}) => (
                  (disabled) ?
                    <Button disabled={disabled}
                      key={title}
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, display: 'block' }}
                      style={{ color: 'grey' }}
                    >
                      {title}
                    </Button> :

                    <NavLink className="nav-header" color="inherit" to={route} key={title}>
                      <Button

                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'inherit', display: 'block' }}
                      >
                        {title}
                      </Button>
                    </NavLink>
                ))}
              </Box>

              {isAdmin() ?
                <Box sx={{ flexGrow: 0 }}>
                  <NavLink className="nav-header" color="inherit" to={'/adminArea'} key={'Admin area'}>
                    <Tooltip title="Admin area">
                      <IconButton
                        sx={{ p: 0 }}
                        className="nav-header"
                        color="inherit" >
                        <AdminPanelSettingsIcon />
                      </IconButton>
                    </Tooltip>
                  </NavLink>
                </Box>
                :
                <></>
              }

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Logout">
                  <IconButton
                    sx={{ p: 1 }}
                    className="nav-header"
                    color="inherit"
                    onClick={handleLogout} >
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </> : <></>}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
