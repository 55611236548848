import React from 'react';
import { CardActionArea, CardHeader, Skeleton } from "@mui/material";


export const SkeletonCardEntryLoader = () => {
  return (
    <CardActionArea
      data-testid={'skeletonCardLoader'}
    >
      <CardHeader
        avatar={
          <Skeleton variant="circular" width={40} height={40} />
        }
        title={<Skeleton width="70%" />}
        subheader={<Skeleton width="60%" />}
        // action={
        //   <Chip
        //     variant="outlined"
        //     label={<Skeleton width={20} />}
        //   />
        // }
      />
    </CardActionArea>
  );
};
