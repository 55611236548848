import { ListSubheader, MenuItem, TextField } from '@mui/material';
import React from 'react';

export function QualityTestDropdown({
  qualityTests, technical_type_id, semantical_type_id,
  selectedTestId, disabled, setter, sx={mr: 2, mb: 2}
}) {
  return (
    <TextField
      data-testid={'select-quality-test'}
      id="outlined-select-test-name"
      sx={sx}
      select
      required
      inputProps={{
        id: "select-quality-test-input"
      }}
      disabled={disabled}
      label="Select"
      value={selectedTestId}
      onChange={(event) => setter(event.target.value)}
      helperText="Please select the test"
    >
      <ListSubheader>Technical</ListSubheader>
      {qualityTests.map((option) => {
        if (option.test_type === 'technical' && (disabled ||
          (option.valid_technical_types.includes(technical_type_id) &&
          option.valid_semantical_types.includes(semantical_type_id)))
        ) {
          return (
            <MenuItem key={option.quality_test_id} value={option.quality_test_id}>
              {option.quality_test_name}
            </MenuItem>);
        } else {
          return (null);
        }
      })}
      <ListSubheader>Semantic</ListSubheader>
      {qualityTests.map((option) => {
        if (option.test_type === 'semantical' && (disabled ||
          (option.valid_technical_types.includes(technical_type_id) &&
          option.valid_semantical_types.includes(semantical_type_id)))
        ) {
          return (
            <MenuItem key={option.quality_test_id} value={option.quality_test_id}>
              {option.quality_test_name}
            </MenuItem>);
        } else {
          return (null);
        }
      })}
    </TextField>
  );
}


export function SemanticQualityTestDropdown({
  qualityTests, technical_type_id, semantical_type_id,
  selectedTestId, disabled, setter, sx={mr: 2, mb: 2}
}) {
  return (
    <TextField
      data-testid={'select-quality-test'}
      id="outlined-select-test-name"
      sx={sx}
      select
      required
      inputProps={{
        id: "select-quality-test-input"
      }}
      disabled={disabled}
      label="Select"
      value={selectedTestId}
      onChange={(event) => setter(event.target.value)}
      helperText="Please select the test"
    >
      {qualityTests.map((option) => {
        if (option.test_type === 'semantical' && (disabled ||
          (option.valid_technical_types.includes(technical_type_id) &&
          option.valid_semantical_types.includes(semantical_type_id)))
        ) {
          return (
            <MenuItem key={option.quality_test_id} value={option.quality_test_id}>
              {option.quality_test_name}
            </MenuItem>);
        } else {
          return (null);
        }
      })}
    </TextField>
  );
}


export function AlertDropdown({
  selectedAlert, disabled=false, setter, sx={mr: 2, mb: 2}
}) {
  return (
    <TextField
      data-testid={'select-test-consequence'}
      id="test-consequence-name"
      sx={sx}
      select
      required
      inputProps={{
        id: "select-quality-test-input"
      }}
      disabled={disabled}
      label="Select"
      value={selectedAlert}
      onChange={(event) => setter(event.target.value)}
      helperText="Please select the how a failed test should be handled."
    >
      <MenuItem value={'warning'}>Warning</MenuItem>
      <MenuItem value={'error'}>Error</MenuItem>
    </TextField>
  );
}
