/* eslint-disable react/jsx-filename-extension */
import React, { Suspense, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import Login from './Login';
import Home from './home';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import reportWebVitals from './reportWebVitals';


import { theme } from './theme.js';

// import i18n (needs to be bundled ;))
import './i18n';

import "./assets/App.css";
import AuthContext, {AuthProvider} from './contexts/authContext';
import FeedOverview from './feedOverview';
import CreateFeed from './createFeed';
import AdminArea from './pages/Admin/AdminArea';
import Documentation from './pages/Documentation/Documentation';
import { DomainProvider } from './contexts/domainContext';
import CreateVariable from './pages/CreateVariable/createVariable';
import FeedExecutionOverview from './pages/FeedExecutionOverview/FeedExecutionOverview';
import { FeedExecutionTableContextProvider } from './pages/FeedExecutionOverview/FeedExecutionTableContext';
import PipelineDetails from './pages/PipelineDetails/PipelineDetails';
import DomainTables from './pages/DomainTables/DomainTables';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import HandleMultivariateTests from './pages/MultivariateTests/multivariateTests';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Suspense fallback="Loading">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <DomainProvider>
                <FeedExecutionTableContextProvider>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path='/home' element={<RequireAuth><Home /></RequireAuth>} />
                    <Route path='/feeds' element={<RequireAuth><FeedOverview /></RequireAuth>} />
                    <Route path='/pipelines' element={<RequireAuth><FeedExecutionOverview /></RequireAuth>} />
                    <Route path='/pipelineDetails/:executionId'
                      element={<RequireAuth><PipelineDetails /></RequireAuth>} />
                    <Route path='/createFeed' element={<RequireAuth><CreateFeed /></RequireAuth>} />
                    <Route path='/editFeed/:feedId' element={<RequireAuth><CreateFeed /></RequireAuth>} />
                    <Route path='/createVariable/:feedId' element={<RequireAuth><CreateVariable /></RequireAuth>} />
                    <Route path='/multivariateTests/:feedId'
                      element={<RequireAuth><HandleMultivariateTests /></RequireAuth>} />
                    <Route path='/adminArea' element={<RequireAdmin><AdminArea /></RequireAdmin>} />
                    <Route path='/documentation' element={<RequireAuth><Documentation /></RequireAuth>} />
                    <Route path='/corrections' element={<RequireAuth><Documentation /></RequireAuth>} />
                    <Route path='/domaintables' element={<RequireAuth><DomainTables /></RequireAuth>} />
                  </Routes>
                </FeedExecutionTableContextProvider>
              </DomainProvider>
            </AuthProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
);

function RequireAuth({ children }) {
  const { authed } = useContext(AuthContext);
  const location = useLocation();

  return authed === true
    ? children
    : (<Navigate to="/" replace state={{ path: location.pathname }} />
    );
}

function RequireAdmin({ children }) {
  const { isAdmin, isAuthed } = useContext(AuthContext);

  return isAdmin() === true && isAuthed() === true
    ? children
    : (<Navigate to="/" />
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
