// import { useNavigate } from "react-router-dom";

import { checkAuthenticated, createHeader } from "./communicationHelper";

function fetchGet(token, route) {
  fetch(route, {
    method: 'POST',
    headers: createHeader(token),
  }).then(res => res.json());
}

// Handles the server communication
async function deactivateFeed(token, feedId, setFeeds, feeds) {
  await fetch('/deactivate_feed/' + feedId, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
  setFeeds(feeds.filter(feed => feed.feed_id !== feedId));
}

async function requestFeedInformation(token, feedId, setFeed, setWarningEmailRecipients) {
  await fetch(`/request_feed_information/${feedId}`, {
    method: 'POST',
    headers: createHeader(token),
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === "Success") {
          setFeed(result.feed);
          if (result.feed.warning_email_recipients === '') {
            setWarningEmailRecipients([]);
          } else {
            setWarningEmailRecipients(result.feed.warning_email_recipients.split(','));
          }
        } else {
          setFeed([]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function insertUpdateFeed(
  token, feed, warningEmailRecipients, feedTests,
  navigateToCreateVariable) {
  // Add the warningEmailRecipients to the feed
  feed.warning_email_recipients = warningEmailRecipients;
  await fetch('/insert_update_feed', {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({feed: feed, feed_tests: feedTests})
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          navigateToCreateVariable(result.feed.feed_id);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}


async function copyFeed(
  token, feedId, setFeeds) {
  await fetch(`/copy_feed/${feedId}`, {
    method: 'POST',
    headers: createHeader(token)
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setFeeds(a => [...a, result.new_feed]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function closeStream(
  token, execution_result_id, setPipeline) {
  await fetch(`/close_pipeline_streaming_frontend/${execution_result_id}`, {
    method: 'POST',
    headers: createHeader(token)
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setPipeline(a => (
            {...a, execution_successfull: result.data,
              execution_close_time: result.close_time}));
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function insertUpdateVariable(
  token, variable, variables, setVariables) {
  await fetch('/insert_update_variable', {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      variable: variable,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        // First check if the element is in array
        const index = variables.findIndex(e => e.variable_id === result.variable.variable_id);

        if (index !== -1) {
          // Update existing entry
          setVariables(a => [
            ...a.slice(0, index),
            result.variable,
            ...a.slice(index + 1, a.length),
          ]);
        } else {
          // Insert new variable
          setVariables(a => [...a, result.variable]);
        }
      },
      (error) => {
        console.error(error);
        return undefined;
      }
    );
}

/**
 * Calls a delete function on the server for given url
 * On success the onSuccessFunction is called
 * @param {*} token
 * @param {*} url
 * @param {*} onSuccessFunction
 */
async function deleteEntry(token, url, onSuccessFunction) {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: createHeader(token),
    });

    if (response.ok) {
      onSuccessFunction();
    }

  } catch (error) {
    console.error(error);
  }

}

async function insertUser(
  token, user, setUser) {
  await fetch(`/insert_user`, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      user: user,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setUser(a => [...a, result.data]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function updateUserPassword(
  token, updatedUser, setPasswordNotification) {
  await fetch(`/update_user_password/${updatedUser['user_id']}`, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      user: updatedUser,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setPasswordNotification(true);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}


async function updateUserPermission(
  token, updatedUser, users, setUser) {
  await fetch(`/update_user_permission/${updatedUser['user_id']}`, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      user: updatedUser,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {//
          const index = users.findIndex(e => e.user_id === result.data.user_id);
          setUser(a => [
            ...a.slice(0, index),
            result.data,
            ...a.slice(index + 1, a.length),
          ]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function deactivateUser(
  token, user_id, setUser) {
  await fetch(`/deactivate_user/${user_id}`, {
    method: 'DELETE',
    headers: createHeader(token),
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setUser(a => a.filter(e => e.user_id !== user_id));
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

// Handles the permission server communication
async function addCollaborator(token, feedId, email,
  permission, setCollaborators, setErrorMsg, setNewCollaborator, setNewCollaboratorPermission) {
  await fetch('/add_collaborator_email/' + feedId, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      email: email,
      permission: permission,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setCollaborators(a => [...a, result.data]);
          setNewCollaborator('');
          setNewCollaboratorPermission('view');
          setErrorMsg("");
        } else {
          setErrorMsg("An error occured for: " + email);
        }
      },
      (error) => {
        console.error(error);
        setErrorMsg("An error occurred");
      }
    );
}

async function updateCollaborator(token, userId, feedId,
  permission, collaborators, setCollaborators) {
  await fetch(`/insert_update_collaborator/${feedId}/${userId}`, {
    method: 'POST',
    headers: createHeader(token),
    body: JSON.stringify({
      permission: permission,
    })
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          const index = collaborators.findIndex(e => e.user_id === userId);
          // Include other personal information here as only the user_id is being returned
          const tempCollaborator = collaborators[index];
          const newCollaborator = result.data;
          newCollaborator['email'] = tempCollaborator.email;
          setCollaborators(a => [
            ...a.slice(0, index),
            result.data,
            ...a.slice(index + 1, a.length),
          ]);
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

async function revokeCollaborator(
  token, user_id, feed_id, setCollaborators) {
  await fetch(`/revoke_collaborator/${feed_id}/${user_id}`, {
    method: 'DELETE',
    headers: createHeader(token),
  }).then(res => res.json())
    .then(
      (result) => {
        checkAuthenticated(result);
        if (result.msg === 'Success') {
          setCollaborators(a => a.filter(e => e.user_id !== user_id));
        }
      },
      (error) => {
        console.error(error);
      }
    );
}

export {
  insertUpdateFeed, deactivateFeed, requestFeedInformation,
  insertUpdateVariable,
  copyFeed, closeStream,
  deleteEntry, insertUser, updateUserPassword, updateUserPermission, deactivateUser,
  fetchGet,
  addCollaborator, revokeCollaborator, updateCollaborator,
};
